import { Observable } from 'rxjs';
import { PaginatedData } from 'src/base/wrappers/paginated-data';

import { UseCase } from 'src/domain/base/use-case';
import { TransactionModel } from 'src/domain/models/transaction.model';
import { CatalogRepository } from 'src/domain/repositories/catalog.repository';
import { TravelPlanRepository } from 'src/domain/repositories/travel-plan.repository';
export class GetCatalogContributionPricesUseCase
  implements UseCase<{ planId: string }, PaginatedData<number>>
{
  constructor(private catalogRepository: CatalogRepository) {}

  execute(): Observable<PaginatedData<number>> {
    return this.catalogRepository.getCatalogContributionPrices();
  }
}

