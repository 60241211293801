import { Mapper } from 'src/base/utils/mapper';

import { PlanContractEntity as PlanContracEntity } from '../entities/plan-contract.entity';
import { PlanContractModel } from 'src/domain/models/plan-contract/plan-contract.model';
import {
  TravelPlanModel,
  TravelPlanState,
} from 'src/domain/models/travel-plan.model';
export class PlanContractMapper extends Mapper<
  PlanContracEntity,
  PlanContractModel
> {
  override mapFrom(param: PlanContracEntity): PlanContractModel {
    return {
      ...param,

      monthlyContributionPrice: param.monthlyContributionPrice,
      totalSaving: param.valueSaved,
      totalBalance: param.planValue,
      nextContributionDate: param.nextContributionDate,
      lastContributionAt: param.lastContributionDate,
    }

    };


  override mapTo(param: PlanContractModel): PlanContracEntity {
    throw new Error('Method not implemented.');
  }
}
