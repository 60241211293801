<div class="header-menu">
  <a class="navbar-brand p-0" routerLink="/account">
    <img  src="../../../../../assets/images/CVU_logo.svg" alt="Logo" class="logo" />
  </a>
  <div  class="buttons" [routerLink]="backRouter">
    <img src="../../../../../assets/icons/person_icon.svg" class="person-button" />
     <img src="../../../../../assets/icons/menu_icon.svg" class="menu-button" />
  </div>

</div>
