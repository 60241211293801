import { Injectable } from '@angular/core';
import { ResultsEntity } from 'src/domain/simulation/entities/results.entities';

@Injectable({
    providedIn: 'root'
})
export class ResultsService {
    results: ResultsEntity | undefined;

    constructor() { }
}