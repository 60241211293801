import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRepository } from 'src/domain/repositories/user.repository';
import { UserImplRepository } from './user-impl.repository';
import { GetCertificationByTypeUseCase } from 'src/domain/usecases/user/get-certification-by-type.usecase';
import { GetUserUseCase } from 'src/domain/usecases/user/get-user.usecase';
import { PutUserUseCase } from 'src/domain/usecases/user/put-user.usecase';
import { PostUserFirebaseUseCase } from 'src/domain/usecases/user/post-user-firebase.usecase';
import { GetUserFirebaseUseCase } from 'src/domain/usecases/user/get-user-firebase.usecase';
import { PostRefundRequestUseCase } from 'src/domain/usecases/user/post-refund-request.usecase';




const getCertificationsByTypeUseCaseFactory = (
  userRepository: UserRepository
) => new GetCertificationByTypeUseCase(userRepository);

const getCertificationsByTypeUseCaseProvider = {
  provide: GetCertificationByTypeUseCase,
  useFactory: getCertificationsByTypeUseCaseFactory,
  deps: [UserRepository],
};


const getUserUseCaseFactory = (
  userRepository: UserRepository
) => new GetUserUseCase(userRepository);

const getUserUseCaseProvider = {
  provide: GetUserUseCase,
  useFactory: getUserUseCaseFactory,
  deps: [UserRepository],
};

const putUserUseCaseFactory = (
  userRepository: UserRepository
) => new PutUserUseCase(userRepository);

const putUserUseCaseProvider = {
  provide: PutUserUseCase,
  useFactory: putUserUseCaseFactory,
  deps: [UserRepository],
};

const postUserFirebaseUseCaseFactory = (
  userRepository: UserRepository
) => new PostUserFirebaseUseCase(userRepository);

const postUserFirebaseUseCaseProvider = {
  provide: PostUserFirebaseUseCase,
  useFactory: postUserFirebaseUseCaseFactory,
  deps: [UserRepository],
};


const getUserFirebaseUseCaseFactory = (
  userRepository: UserRepository
) => new GetUserFirebaseUseCase(userRepository);

const getUserFirebaseUseCaseProvider = {
  provide: GetUserFirebaseUseCase,
  useFactory: getUserFirebaseUseCaseFactory,
  deps: [UserRepository],
};

const postRefundRequestUseCaseFactory = (
  userRepository: UserRepository
) => new PostRefundRequestUseCase(userRepository);

const postRefundRequestUseCaseProvider = {
  provide: PostRefundRequestUseCase,
  useFactory: postRefundRequestUseCaseFactory,
  deps: [UserRepository],
};


const userRepositoryProvider = {
  provide: UserRepository,
  useClass: UserImplRepository,
};

@NgModule({
  providers: [
    getCertificationsByTypeUseCaseProvider,
    getUserUseCaseProvider,
    putUserUseCaseProvider,
    postUserFirebaseUseCaseProvider,
    getUserFirebaseUseCaseProvider,
    postRefundRequestUseCaseProvider,
    userRepositoryProvider,

  ],
  imports: [CommonModule],
})
export class UserModule {}
