import { Observable } from 'rxjs';
import { PaginatedData } from 'src/base/wrappers/paginated-data';

import { UseCase } from 'src/domain/base/use-case';
import { CatalogModel } from 'src/domain/models/catalog.model';
import { CatalogRepository } from 'src/domain/repositories/catalog.repository';
export class GetCatalogDocumentTypesUseCase
  implements UseCase<{pageNumber?: number; pageSize?: number; }, PaginatedData<CatalogModel>>
{
  constructor(private catalogRepository: CatalogRepository) {}

  execute(
    params : {pageNumber?: number, pageSize?: number, }
  ): Observable<PaginatedData<CatalogModel>> {
    return this.catalogRepository.getCatalogDocumentTypes(params.pageNumber ?? 1,
      params.pageSize ?? 100);
  }
}
