import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ResponseData } from 'src/base/wrappers/response-data';
import { ConfirmOtpResponseModel } from 'src/domain/models/confirm-otp-response.model';
import { SendOtpResponseModel } from 'src/domain/models/send-otp-response.model';
import { ValidateOtpRepository } from 'src/domain/repositories/validate-otp.repository';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ValidateOtpImplRepository extends ValidateOtpRepository {
  private readonly userUrl: string;

  constructor(private http: HttpClient) {
    super();
    this.userUrl = `${environment.uriBase}Validate`;
  }
  override sendOtp(): Observable<SendOtpResponseModel> {
    return this.http
      .post<ResponseData<SendOtpResponseModel>>(`${this.userUrl}/send-code`, {})
      .pipe(
        map((result) => {
          return result.data;
        })
      );
  }
  override confirmOtp(code: string): Observable<ConfirmOtpResponseModel> {
    return this.http
      .post<ResponseData<ConfirmOtpResponseModel>>(
        `${this.userUrl}/confirm-code`,
        { code: code }
      )
      .pipe(
        map((result) => {
          return result.data;
        })
      );
  }
}
