<div class="container-cards">
  <div *ngFor="let item of plan?.products; let i = index" class="accordion accordion-flush" [id]="'accordioncard' + i">
    <div class="accordion-item ctn-card">
      <h2 class="accordion-header">
        <button class="accordion-button cuotes" [ngClass]="isViewportSmall ? i > 0 ? 'collapsed' : '' : ''"
          type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapseOne' + i" aria-expanded="true"
          [attr.aria-controls]="'flush-collapseOne' + i" style="background-color: transparent; padding: 1rem 0;">
          {{item.numberContributions}} cuotas
        </button>
      </h2>
      <div [id]="'flush-collapseOne' + i" class="accordion-collapse collapse"
        [ngClass]="isViewportSmall ? i < 1 ? 'show' : '' : 'show'" [attr.data-bs-parent]="'#accordioncard' + i">
        <div class="accordion-body-main">
          <h3 class="p-0 my-2 text-start">{{ item.savingsPlan.name }}</h3>
          <div class="row mt-3">
            <div class="col">
              <h1 class="save-text">Ahorro mensual</h1>
              <h2 class="price">${{item.contributionPrice | currency : "COP" : "" : "1.0-0" :
                "es-CO"}} COP
              </h2>
            </div>
            <div class="col">
              <h1 class="save-text">Ahorro diario</h1>
              <h2 class="price">${{item.dailyPrice | currency : "COP" : "" : "1.0-0" :
                "es-CO"}} COP</h2>
            </div>
          </div>
          <div class="mt-3">
            <h1 class="save-text">Ahorro estimado total<br> <span class="pricetotal">${{item?.totalPrice | currency :
                "COP" : "" : "1.0-0" :
                "es-CO"}} COP
              </span>
            </h1>
          </div>

          <div class="d-flex my-4">
            <button (click)="initPlanNav(item.numberContributions, item.savingsPlan.id)" class="btn-init">Iniciar
              plan</button>
          </div>

          <div class="accordion accordion-flushtab" [id]="'accordiontable' + i">
            <div class="accordion-itemtab child">
              <h2 class="accordion-header">
                <button (click)="toggleAccordion(i)"
                  class="accordion-button collapsed text-table-deaits accordion-table" type="button"
                  data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapseTwo' + i" aria-expanded="false"
                  [attr.aria-controls]="'flush-collapseTwo' + i"
                  style="padding: 0 0 0 0; background-color: transparent;">
                  {{ openAccordionIndex === i ? 'Ocultar tabla de anticipos' : 'Ver tabla de anticipos' }}
                </button>


              </h2>
              <div [id]="'flush-collapseTwo' + i" class="accordion-collapse collapse"
                [attr.data-bs-parent]="'#accordiontable' + i">
                <div class="accordion-body" style="padding: 0 0 0 0;">
                  <app-table [product]="item"></app-table>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion accordion-flushtab mt-3" [id]="'accordionben' + i">
            <div class="accordion-itemtab">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed text-table-deaits accordion-benefits" type="button"
                  data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapseben' + i" aria-expanded="false"
                  [attr.aria-controls]="'flush-collapseben' + i"
                  style="padding: 0 0 0 0; background-color: transparent;">
                  Beneficios adicionales
                </button>
              </h2>
              <div [id]="'flush-collapseben' + i" class="accordion-collapse collapse"
                [attr.data-bs-parent]="'#accordionben' + i">
                <div class="accordion-body" style="padding: 0 0 0 0;">
                  <div style="margin-top: 16px;">
                  </div>
                  <div class="d-flex">
                    <img class="icn-check" src="\assets\icons\check.svg" alt="Icono" />
                    <h1 class="text-benefits">
                      Sorteo mensual en el cual puedes ganar el valor total que proyectas ahorrar.
                    </h1>
                  </div>
                  <div class="d-flex">
                    <img class="icn-check" src="\assets\icons\check.svg" alt="Icono" />
                    <h1 class="text-benefits">Noches de hospedaje.</h1>
                  </div>
                  <div class="d-flex">
                    <img class="icn-check" src="\assets\icons\check.svg" alt="Icono" />
                    <h1 class="text-benefits">Puntos para redimir en comercios aliados.</h1>
                  </div>
                  <div class="d-flex mt-3">
                    <span class="title-back text-start">
                      Los beneficios dependen del plan y valor de aporte seleccionado. Aplican condiciones y restricciones.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
