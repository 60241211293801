import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TravelPlanRepository } from 'src/domain/repositories/travel-plan.repository';
import { GetTravelPlansUseCase } from 'src/domain/usecases/travel-plan/get-plans.usecase';
import { TravelPlanImplRepository } from './travel-plan-impl.repository';
import { GetTravelPlanUseCase } from 'src/domain/usecases/travel-plan/get-plan.usecase';
import { GetPaymentSummaryUseCase } from 'src/domain/usecases/plan-contract/get-payment-summary.usecase';
import { GetTravelPlansSummaryUseCase } from 'src/domain/usecases/travel-plan/get-plans-summary.usecase';
import { GetTransationsListUseCase } from 'src/domain/usecases/plan-contract/get-transactions-list.usecase';
import { GetPlanSummaryUseCase } from 'src/domain/usecases/travel-plan/get-plan-summary.usecase';
import { PostTransactionContributionUseCase } from 'src/domain/usecases/plan-contract/post-transaction-contribution.usecase';
import { DeletePlanUseCase } from 'src/domain/usecases/travel-plan/delete-plan.usecase';
import { PatchEditPlanUseCase } from 'src/domain/usecases/travel-plan/patch-edit-plan.usecase';
import { GetAvailableContractForSubscriptionUseCase } from 'src/domain/usecases/plan-contract/get-available-contract-for-subscription.usecase';
import { SignContractUseCase } from 'src/domain/usecases/travel-plan/sign-contract.usecase';
import { GetAvailableContractForContributionUseCase } from 'src/domain/usecases/plan-contract/get-available-contract-for-contribution.usecase';

const getTravelPlansUseCaseFactory = (
  travelPlanRepository: TravelPlanRepository
) => new GetTravelPlansUseCase(travelPlanRepository);

const getTravelPlansUseCaseProvider = {
  provide: GetTravelPlansUseCase,
  useFactory: getTravelPlansUseCaseFactory,
  deps: [TravelPlanRepository],
};



const getTravelPlanUseCaseFactory = (
  travelPlanRepository: TravelPlanRepository
) => new GetTravelPlanUseCase(travelPlanRepository);

const getTravelPlanUseCaseProvider = {
  provide: GetTravelPlanUseCase,
  useFactory: getTravelPlanUseCaseFactory,
  deps: [TravelPlanRepository],
};

const getTravelPlansSummaryUseCaseFactory = () =>
  new GetTravelPlansSummaryUseCase();

const getTravelPlansSummaryUseCaseProvider = {
  provide: GetTravelPlansSummaryUseCase,
  useFactory: getTravelPlansSummaryUseCaseFactory,
  deps: [TravelPlanRepository],
};



const getPlanSummaryUseCaseFactory = (
  travelPlanRepository: TravelPlanRepository
) => new GetPlanSummaryUseCase(travelPlanRepository);

const getTravelPlanSummaryUseCaseProvider = {
  provide: GetPlanSummaryUseCase,
  useFactory: getPlanSummaryUseCaseFactory,
  deps: [TravelPlanRepository],
};



const deletePlanUseCaseFactory = (
  travelPlanRepository: TravelPlanRepository
) => new DeletePlanUseCase(travelPlanRepository);

const deletePlansUseCaseProvider = {
  provide: DeletePlanUseCase,
  useFactory: deletePlanUseCaseFactory,
  deps: [TravelPlanRepository],
};


const patchEditPlanUseCaseFactory = (
  travelPlanRepository: TravelPlanRepository
) => new PatchEditPlanUseCase(travelPlanRepository);

const patchEditPlanUseCaseProvider = {
  provide: PatchEditPlanUseCase,
  useFactory: patchEditPlanUseCaseFactory,
  deps: [TravelPlanRepository],
};





const signContractUseCaseFactory = (
  travelPlanRepository: TravelPlanRepository
) => new SignContractUseCase(travelPlanRepository);

const signContractUseCaseProvider = {
  provide: SignContractUseCase,
  useFactory: signContractUseCaseFactory,
  deps: [TravelPlanRepository],
};


const travelPlanRepositoryProvider = {
  provide: TravelPlanRepository,
  useClass: TravelPlanImplRepository,
};

@NgModule({
  providers: [
    getTravelPlansUseCaseProvider,
    getTravelPlanUseCaseProvider,
    getTravelPlansSummaryUseCaseProvider,
    getTravelPlanSummaryUseCaseProvider,
    deletePlansUseCaseProvider,
    patchEditPlanUseCaseProvider,

    signContractUseCaseProvider,
    travelPlanRepositoryProvider,
  ],
  imports: [CommonModule],
})
export class TravelPlanModule {}
