import { Observable } from 'rxjs';

import { UseCase } from 'src/domain/base/use-case';
import { UserFirebaseModel } from 'src/domain/models/user-firebase.model';
import { UserModel } from 'src/domain/models/user.model';
import { UserRepository } from 'src/domain/repositories/user.repository';
import { user } from '@angular/fire/auth';
export class PostRefundRequestUseCase
  implements
    UseCase<
      {
        userID: string;
        refundForm: File;
        supports: File[];
        description: string;
      },
      UserModel
    >
{
  constructor(private userRepository: UserRepository) {}

  execute(params: {
    userID: string;
    refundForm: File;
    supports?:  File[];
    description: string;
  }): Observable<UserModel> {
    return this.userRepository.postRefundRequest(
      params.userID,
      params.refundForm,
      params.supports??[],
      params.description
    );
  }
}
