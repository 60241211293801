import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'shared-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  @Input()
  public textHeader: string = '';

  @Input()
  public backRouter: string = '';

  @Input()
  isIcon: boolean = true;

  constructor(private location: Location, private router: Router) {}

  goToBack() {
    if (this.backRouter == '') {
      this.location.back();
      return;
    }

    this.router.navigate([this.backRouter]);
  }
}
