import { Observable } from 'rxjs';

import { UseCase } from 'src/domain/base/use-case';
import { TravelPlanModel } from 'src/domain/models/travel-plan.model';
import { TravelPlanRepository } from 'src/domain/repositories/travel-plan.repository';

export class GetTravelPlanUseCase
  implements UseCase<{ id: string }, TravelPlanModel>
{
  constructor(private travelPlanRepository: TravelPlanRepository) {}

  execute(params: { id: string }): Observable<TravelPlanModel> {
    return this.travelPlanRepository.getPlan(params.id);
  }
}
