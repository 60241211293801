<div class="rating-meaning">
  {{ ratingMeaning }}
</div>
<div class="rating">

  <span *ngFor="let star of starRange" (click)="setRating(star)">
    <img [src]="star <= rating ? 'assets/icons/star_filled.svg' : 'assets/icons/star_empty.svg'" alt="Star">
  </span>
</div>

