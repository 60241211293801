<div class="background-container">
  <img class="img-bg" src="assets\images\landing\banner\Wave.png" alt="Decoración">
  <div class="container web" style="margin-top: 50px;">
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12">
            <div class="fixed-width-container">
              <h2>El viaje de tus sueños en tan solo 4 pasos</h2>
              <div class="row">
                <div class="col-md-12">
                  <div>
                    <button class="steps" style="pointer-events: none;">1</button>
                    <div style="overflow: hidden;">
                      <h4>Conoce un costo de referencia estimado para el viaje que proyectas</h4>
                      <p class="mb-2">Indica el destino, el número de viajeros y la duración de tu viaje</p>
                      <a routerLink="/simulator" class="enlace">Comenzar <span
                          class="material-symbols-outlined arrow">arrow_forward_ios</span></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <img src="\assets\images\landing\banner\Step1.png" style="margin-left: 41px;" alt="">
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <img src="\assets\images\landing\banner\Step2.png" style=" margin-top: 80px; margin-left: 55px;" alt="">
              </div>
            </div>

            <div class="container fixed-width-container" style=" margin-top: 50px;">
              <div class="row">
                <div class="col-md-12">
                  <div>
                    <button class="steps" style="pointer-events: none;">3</button>
                    <div style="overflow: hidden;">
                      <h4>Realiza tus aportes</h4>
                      <p class="mb-2">Fácilmente a través de nuestra plataforma</p>
                      <a routerLink="/simulator" class="enlace">Comenzar <span
                          class="material-symbols-outlined arrow">arrow_forward_ios</span></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <img src="\assets\images\landing\banner\iPhone 15 Pro.png" alt="">
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12" style="margin-top: 230px; ">
            <div style="margin-top: 100px;">
              <div class="container-fixed">
                <div class="contianer-cards">
                  <div class="card">
                    <img src="\assets\images\landing\banner\Cancun.png" alt="Destino">
                    <div class="card-button d-flex align-items-end">
                      <button class="btn-card" routerLink="/simulator">Simular ahorro</button>
                    </div>
                  </div>
                  <div class="card">
                    <img src="\assets\images\landing\banner\Madrid.png" alt="Destino">
                    <div class="card-button d-flex align-items-end">
                      <button class="btn-card" routerLink="/simulator">Simular ahorro</button>
                    </div>
                  </div>
                  <div class="card">
                    <img src="\assets\images\landing\banner\Medellin.png" alt="Destino">
                    <div class="card-button d-flex align-items-end">
                      <button class="btn-card" routerLink="/simulator">Simular ahorro</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="container fixed-width-container" style="margin-top: 130px;">
              <div class="row">
                <div class="col-md-12">
                  <div>
                    <button class="steps" style="pointer-events: none;">2</button>
                    <div style="overflow: hidden;">
                      <h4>Elige tu plan de ahorro</h4>
                      <p class="mb-2">Selecciona la alternativa que más se ajusta a tu expectativa y capacidad de ahorro mensual</p>
                      <a routerLink="/simulator" class="enlace">Comenzar <span
                          class="material-symbols-outlined arrow">arrow_forward_ios</span></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" style="margin-top: 50px; margin-bottom: 50px;">
              <div class="col-md-12 d-flex justify-content-center">
                <button class="cuotes" style="color: #CC9E00; pointer-events: none;" disabled>
                  <div class="d-flex flex-column align-items-center">
                    <span style="margin-bottom: auto;">36</span>
                    <span class="aportes" style="margin-top: auto;">Aportes</span>
                  </div>
                </button>

                <button class="cuotes" style="color: #90BD00;pointer-events: none;" disabled>
                  <div class="d-flex flex-column align-items-center">
                    <span style="margin-bottom: auto;">24</span>
                    <span class="aportes" style="margin-top: auto;">Aportes</span>
                  </div>
                </button>
                <button class="cuotes" style="color: #21338A; pointer-events: none;" disabled>
                  <div class="d-flex flex-column align-items-center">
                    <span style="margin-bottom: auto;">18</span>
                    <span class="aportes" style="margin-top: auto;">Aportes</span>
                  </div>
                </button>
              </div>
            </div>

            <div class="row" style="margin-top: 115px;">
              <div class="col-md-12">
                <img src="\assets\images\landing\banner\Step3.png" alt="">
              </div>
            </div>

            <div class="container fixed-width-container" style=" margin-top: 20px;">
              <div class="row">
                <div class="col-md-12">
                  <div>
                    <button class="steps" style="pointer-events: none;">4</button>
                    <div style="overflow: hidden;">
                      <h4>Redime tu viaje</h4>
                      <p class="mb-2">
                        Recibe nuestra asesoría y convierte tus ahorros en paquetes turísticos,
                        pasajes aéreos, tours, alojamiento, alimentación y mucho más
                      </p>
                      <a routerLink="/simulator" class="enlace">Comenzar <span
                          class="material-symbols-outlined arrow">arrow_forward_ios</span></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mobile mb-5">
  <img class="img-bg-mobile" src="assets\images\landing\banner\Wave-mobile.png" alt="Decoración">
  <div class="columna">
    <div class="fila-title container mt-3">
      <h2>El viaje de tus sueños en tan solo 4 pasos</h2>
    </div>
    <div class="line-steps d-sm-none"></div>
    <div class="fila container">
      <div class="row">
        <div class="col-md-12">
          <div class="step-container">
            <div class="step-number">
              <div class="steps">1</div>
              <div class="linea"></div>
            </div>
            <div class="overflow-hidden">
              <h4>Conoce un costo de referencia estimado para el viaje que proyectas</h4>
              <p class="mb-2">Indica el destino, el número de viajeros y la duración de tu viaje</p>
              <a routerLink="/simulator" class="enlace">Comenzar <span
                  class="material-symbols-outlined arrow">arrow_forward_ios</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fila container mt-3">
      <div class="row">
        <div class="col-md-12">
          <div class="step-container">
            <div class="step-number">
              <div class="steps">2</div>
              <div class="linea"></div>
            </div>
            <div class="overflow-hidden">
              <h4>Elige tu plan de ahorro</h4>
              <p class="mb-2">Selecciona la alternativa que más se ajusta a tu expectativa y capacidad de ahorro mensual
              </p>
              <a routerLink="/simulator" class="enlace">Comenzar <span
                  class="material-symbols-outlined arrow">arrow_forward_ios</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fila container mt-3">
      <div class="row">
        <div class="col-md-12">
          <div class="step-container">
            <div class="step-number">
              <div class="steps">3</div>
              <div class="linea contributions"></div>
            </div>
            <div class="overflow-hidden">
              <h4>Realiza tus aportes</h4>
              <p class="mb-2">Fácilmente a través de nuestra plataforma</p>
              <a routerLink="/simulator" class="enlace">Comenzar <span
                  class="material-symbols-outlined arrow">arrow_forward_ios</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fila container mt-3">
      <div class="row">
        <div class="col-md-12">
          <div class="step-container">
            <div class="step-number">
              <div class="steps">4</div>
            </div>
            <div class="overflow-hidden">
              <h4>Redime tu viaje</h4>
              <p class="mb-2">Recibe nuestra asesoría y convierte tus ahorros en paquetes turísticos, pasajes aéreos,
                tours, alojamiento, alimentación y mucho más</p>
              <a routerLink="/simulator" class="enlace">Comenzar <span
                  class="material-symbols-outlined arrow">arrow_forward_ios</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
