import { Observable } from 'rxjs';

import { UseCase } from 'src/domain/base/use-case';
import { ContributionModel } from 'src/domain/models/contribution.model';
import { PlanContractRepository } from 'src/domain/repositories/plan-contract.repository';

export class PostTransactionContributionUseCase
  implements UseCase<{ contribution: ContributionModel ,planId:string}, ContributionModel>
{
  constructor(private planContractRepository: PlanContractRepository) {}

  execute(params: { contribution: ContributionModel ,planId:string}): Observable<ContributionModel> {
    return this.planContractRepository.postTransactionContribution(params.contribution,params.planId);
  }
}
