import { CommonModule } from '@angular/common';
import { Component, HostListener, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { TravelPlanModel } from 'src/domain/models/travel-plan.model';
import {
  setTravelPlan,
  setTravelPlanSubscriptionInfo,
} from 'src/presentation/app/account/store/actions/travel-plan.actions';
import { PlanCardComponent } from 'src/presentation/app/shared/components/plan-card/plan-card.component';
import { TableAdvanceAvailableComponent } from '../table/table.component';

@Component({
  selector: 'app-draft-cards',
  templateUrl: './draft-cards.component.html',
  styleUrls: ['./draft-cards.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    PlanCardComponent,
    TableAdvanceAvailableComponent,
  ],
})
export class DraftCardsComponent {
  @Input() plan?: TravelPlanModel;
  isViewportSmall: boolean = false;
  thresholdWidth: number = 770;

  constructor(private router: Router, private store: Store) {
    this.checkViewportWidth(window.innerWidth);
  }

  initPlanNav(numberContributions: number, savingsPlan: string) {
    if (!this.plan?.id) {
      return;
    }

    this.store.dispatch(setTravelPlan({ travelPlan: this.plan }));
    this.store.dispatch(
      setTravelPlanSubscriptionInfo({
        subscriptionInfo: {
          savingsPlanId: savingsPlan,
          numberContributions: numberContributions,
          firstName: '',
          lastName: '',
          birthdate: '',
          gender: '',
          documentTypeId: '',
          documentNumber: '',
          residenceDepartment: '',
          residenceMunicipality: '',
          phoneNumber: '',
          contribution: {
            amount: 0,
            paymentMethod: '',
            transactionCode: '',
          },
          plan: {
            id: '',
            filing: '',
            destination: {
              id: '',
              name: '',
              image: '',
              description: '',
            },
            numberDays: 0,
            numberPeople: 0,
            numberContributions: 0,
            monthlyContributionPrice: 0,
            contributionsMade: 0,
            totalSaving: 0,
            totalBalance: 0,
            nextContributionDate: '',
            savingsPlan: {
              id: '',
              cvuId: 0,
              name: '',
              description: '',
              numberContributions: 0,
              points: 0,
              contributionPrice: 0,
            },
            contractUrl: '',
            user: {
              id: '',
              firebaseUid: '',
              email: '',
              active: true,
              firstName: '',
              lastName: '',
            },
          },
        },
      })
    );
    this.router.navigateByUrl(`account/plan/subscribe/create?idPlan=${this.plan.id}`);
  }

  openAccordionIndex: number = -1;

  toggleAccordion(index: number) {
    if (this.openAccordionIndex === index) {
      this.openAccordionIndex = -1; // Si el mismo acordeón se hace clic de nuevo, ciérralo
    } else {
      this.openAccordionIndex = index;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const viewportWidth = (event.target as Window).innerWidth;
    this.checkViewportWidth(viewportWidth);
  }

  checkViewportWidth(width: number): void {
    this.isViewportSmall = width <= this.thresholdWidth;
  }
}
