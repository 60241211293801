import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { SimulationSource } from './simulation.source';
import { environment } from 'src/environments/environment';
import { ResponseDataPlaceModel } from 'src/domain/simulation/models/place.model';
import { SimulationDataImplementationRepositoryMapper } from '../mappers/simulation.mappers';
import { CalculateDataModel } from 'src/domain/simulation/models/send.data.model';
import { ResultsEntity } from 'src/domain/simulation/entities/results.entities';
import { ResultsModel } from 'src/domain/simulation/models/results.model';
import { ResultsImplementationRepositoryMapper } from '../mappers/results.mappers';
import { ResponseData } from 'src/base/wrappers/response-data';
import { AuthService } from 'src/presentation/app/shared/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SimulationService extends SimulationSource {
  private readonly baseUrl!: string;
  private readonly openPayUriBase!: string;
  private simulationMapper = new SimulationDataImplementationRepositoryMapper();
  private resultsMapper = new ResultsImplementationRepositoryMapper();

  constructor(
    private readonly http: HttpClient,
    private authService: AuthService
  ) {
    super();
    this.baseUrl = `${environment.uriBase}`;
    this.openPayUriBase = `${environment.openPayUriBase}`;
  }

  override getPlaces(): Observable<ResponseDataPlaceModel> {
    return this.http
      .get<ResponseDataPlaceModel>(`${this.baseUrl}Place`)
      .pipe(map(this.simulationMapper.mapFrom));
  }

  override sendDataSimulation(
    userSimulationData: CalculateDataModel
  ): Observable<ResultsEntity> {
    return this.http
      .post<ResultsModel>(
        `${this.baseUrl}TravelPlan/calculate`,
        userSimulationData
      )
      .pipe(
        map((res: any) => this.resultsMapper.mapTo(res.data)),
        catchError((error: HttpErrorResponse) => {
          console.error(error.error);
          return throwError(() => 'Hay un error');
        })
      );
  }
  override getAvailableDays(destinationId: string): Observable<number[]> {
    return this.http
      .get<ResponseData<number[]>>(
        `${this.baseUrl}Place/${destinationId}/available-days`
      )
      .pipe(map((result) => result.data));
  }

  override checkPayment(id: string): Observable<any> {
    return this.http.get<ResponseDataPlaceModel>(
      `${this.openPayUriBase}/travel-plan/findTravelPlanData?filing=${id}`,
      { headers: this.addTokenHeader() }
    );
  }

  override checkPayment2(id: string): Observable<any> {
    return this.http.get<ResponseDataPlaceModel>(
      `${this.openPayUriBase}/travel-plan/confirmTravelPlanPayment?travel_plan_id=${id}`,
      { headers: this.addTokenHeader() }
    );
  }

  private addTokenHeader(): HttpHeaders {
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return headers;
  }
}
