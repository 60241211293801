import { Observable } from "rxjs";
import { UseCase } from "src/domain/base/use-case";
import { ConfirmOtpResponseModel } from "src/domain/models/confirm-otp-response.model";
import { ValidateOtpRepository } from "src/domain/repositories/validate-otp.repository";


export class ConfirmOtpUsecase implements UseCase<{code:string},ConfirmOtpResponseModel>{
  constructor(private validateOtpRepository: ValidateOtpRepository) {

  }
  execute(params: { code: string; }): Observable<ConfirmOtpResponseModel> {
    return this.validateOtpRepository.confirmOtp(params.code);
  }
}
