import { Observable } from 'rxjs';
import { UseCase } from 'src/domain/base/use-case';

import { Injectable, inject } from '@angular/core';
import { SimulationRepository } from '../repositories/simulation.repositories';
import { ResponseDataPlaceModel } from '../models/place.model';

@Injectable({
  providedIn: 'root',
})
export class GetPlacesUseCase implements UseCase<{}, ResponseDataPlaceModel> {
  constructor(private simulationRepository: SimulationRepository) {}
  execute(): Observable<ResponseDataPlaceModel> {
    return this.simulationRepository.getPlaces();
  }
}
