import {
  ApplicationRef,
  ComponentRef,
  Injectable,
  Injector,
  NgZone,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SessionTimeoutDialogComponent } from '../../components/session-timeout-dialog/session-timeout-dialog.component';
import { filter, Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { AuthEventsService } from '../auth-events/auth-events.service';

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  private timeout: any;
  private idleTime: number = 480; // Tiempo de inactividad en segundos (10 minutos)
  private events: string[] = ['mousemove', 'keydown', 'click', 'touchstart'];
  private dialogComponentRef: ComponentRef<SessionTimeoutDialogComponent> | null =
    null;
  private isAuthenticated = false;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private appRef: ApplicationRef,
    private authEventsService: AuthEventsService
  ) {
    this.authEventsService.logout$.subscribe(() => this.stopMonitoring());
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.isAuthenticated) {
          this.startMonitoring();
        } else {
          this.stopMonitoring();
        }
      });
  }

  public startMonitoring() {
    this.resetTimer();
    this.setupListeners();
  }

  public stopMonitoring() {
    this.clearTimer();
    this.clearListeners();
  }

  public setAuthStatus(status: boolean) {
    this.isAuthenticated = status;
  }

  private setupListeners() {
    this.ngZone.run(() => {
      this.events.forEach((event) => {
        window.addEventListener(event, this.resetTimer.bind(this));
      });
    });
  }

  private clearListeners() {
    this.ngZone.run(() => {
      this.events.forEach((event) => {
        window.removeEventListener(event, this.resetTimer.bind(this));
      });
    });
  }

  private resetTimer() {
    clearTimeout(this.timeout);
    if (this.isAuthenticated) {
      this.ngZone.run(() => {
        this.timeout = setTimeout(() => {
          this.authEventsService.emitLogout(); // Emitir evento de logout
          this.setAuthStatus(false);
          this.showTimeoutDialog();
        }, this.idleTime * 1000);
      });
    }
  }

  private clearTimer() {
    clearTimeout(this.timeout);
  }

  private showTimeoutDialog() {
    this.ngZone.run(() => {
      const dialogContainer = document.createElement('div');
      document.body.appendChild(dialogContainer);

      this.dialogComponentRef = this.appRef.bootstrap(
        SessionTimeoutDialogComponent,
        dialogContainer
      );

      this.dialogComponentRef.instance.logout.subscribe(() => {
        this.removeDialog();
      });
    });
  }

  private removeDialog() {
    if (this.dialogComponentRef) {
      this.appRef.detachView(this.dialogComponentRef.hostView);
      this.dialogComponentRef.destroy();
      this.dialogComponentRef = null;
    }
  }
}
