import { Mapper } from 'src/base/utils/mapper';
import { ResultsModel } from 'src/domain/simulation/models/results.model';
import { ResultsEntity } from 'src/domain/simulation/entities/results.entities';

export class ResultsImplementationRepositoryMapper extends Mapper<
  ResultsEntity,
  ResultsModel
> {
  override mapTo(param: ResultsModel): ResultsEntity {
    return { ...param };
  }
  mapFrom(param: ResultsEntity): ResultsModel {
    return { ...param };
  }
}
