import { Observable } from 'rxjs';
import { PaginatedData } from 'src/base/wrappers/paginated-data';

import { UseCase } from 'src/domain/base/use-case';
import { AvailableContractModel } from 'src/domain/models/available-contract.model';
import { PlanContractRepository } from 'src/domain/repositories/plan-contract.repository';
import { TravelPlanRepository } from 'src/domain/repositories/travel-plan.repository';
export class GetAvailableContractForSubscriptionUseCase
  implements UseCase<{}, PaginatedData<AvailableContractModel>>
{
  constructor(private planContractRepository: PlanContractRepository) {}

  execute(): Observable<PaginatedData<AvailableContractModel>> {
    return this.planContractRepository.getAvailableContractForSubscription();
  }
}
