<header class="container gap-2">
  <div *ngIf="textHeader !== ''" class="back-button" (click)="goToBack()">
    <div class="circle">
      <img src="/assets/icons/back_icon.svg" class="icon" alt="Back icon button" />
    </div>
    <div class="text_back d-none d-sm-block">{{textHeader}}</div>
    <div class="text_back d-sm-none m-0">{{textHeader}}</div>
    <div *ngIf="textHeader === ''" class="spacer"></div>
  </div>
  <a class="navbar-brand p-0" routerLink="/account">
    <img *ngIf="isIcon" src="/assets/images/CVU_logo.svg" alt="Logo" class="logo" />
  </a>
</header>
