import { createReducer, on } from '@ngrx/store';
import * as loaderActions from '../actions/loader.actions';

export interface LoaderState {
  show: boolean;
  message?: string;
}

export const initialState: LoaderState = {
  show: false,
};

export const loaderReducer = createReducer(
  initialState,
  on(loaderActions.showLoader, (state, { message }) => ({
    ...state,
    show: true,
    message,
  })),
  on(loaderActions.hideLoader, (state) => ({
    ...state,
    show: false,
    message: undefined,
  }))
);
