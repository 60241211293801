<div class="container-questions mb-5 pb-5 pb-md-1">
  <!-- Encabezado -->
  <div class="header-questions">
    <div class="container-content-header">
      <div class="container content-header">
        <div class="text">Descubre la forma inteligente de viajar</div>
        <div class="button mt-4 pt-2">
          <shared-action-button [routerLink]="['/simulator']">Comenzar</shared-action-button>
        </div>
      </div>
    </div>
  </div>

  <!-- Contenido de preguntas -->
  <div class="container-content-questions container">
    <div class="title-questions">Preguntas frecuentes</div>
    <div class="row content">
      <!-- Primera columna -->
      <div class="col-lg-6">
        <div class="accordion" *ngFor="let faq of column1; let i = index">
          <div class="accordion-item">
            <div class="accordion-title" (click)="toggleAccordion(faq, i)" [class.active]="faq.open">
              {{ faq.pregunta }}
              <div class="accordion-icon" [class.rotate]="faq.open">
                <img src="../../../../../assets/images/landing/icon/down_arrow.svg" alt="">
              </div>
            </div>

            <div class="accordion-content" [style.display]="faq.open ? 'block' : 'none'">
              <div [innerHTML]="formatText(faq.respuesta)"></div>
              <div *ngIf="faq.usos" class="mt-2">
                Puedes usar el plan de ahorro para los siguientes:
                <ul>
                  <li *ngFor="let uso of faq.usos">{{ uso }}</li>
                </ul>
              </div>
              <div *ngIf="faq.alternativas_pago" class="mt-2">
                <ol>
                  <li *ngFor="let uso of faq.alternativas_pago">{{ uso }}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Segunda columna -->
      <div class="col-lg-6">
        <div class="accordion" *ngFor="let faq of column2; let i = index">
          <div class="accordion-item">
            <div class="accordion-title" (click)="toggleAccordion(faq, i + column1.length)" [class.active]="faq.open">{{ faq.pregunta }}
              <div class="accordion-icon" [class.rotate]="faq.open">
                <img src="../../../../../assets/images/landing/icon/down_arrow.svg" alt="">
              </div></div>
            <div class="accordion-content" [style.display]="faq.open ? 'block' : 'none'">
              <div [innerHTML]="formatText(faq.respuesta)"></div>
              <div *ngIf="faq.alternativas_pago" class="mt-2">
                <ol>
                  <li *ngFor="let uso of faq.alternativas_pago">{{ uso }}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
