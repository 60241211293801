import { Observable } from "rxjs";
import { UseCase } from "src/domain/base/use-case";
import { SendOtpResponseModel } from "src/domain/models/send-otp-response.model";
import { ValidateOtpRepository } from "src/domain/repositories/validate-otp.repository";

export class SendOtpUsecase implements UseCase <void,SendOtpResponseModel>{
  constructor(private validateOtpRepository: ValidateOtpRepository) {

  }
  execute(): Observable<SendOtpResponseModel> {
    return this.validateOtpRepository.sendOtp();
  }
}
