import { Observable } from 'rxjs';
import { UserModel } from '../models/user.model';
import { ProfileModel } from '../models/profile.model';
import { UserFirebaseModel } from '../models/user-firebase.model';



export abstract class UserRepository {
  abstract getCertificationByType(idUser: string,typeCertification:string): Observable<any>;

  abstract getUser(idUser: string): Observable<UserModel>;

  abstract putUser(user: ProfileModel,idUser:string): Observable<ProfileModel>;

  abstract postUserFirebase(userFirebase:UserFirebaseModel): Observable<UserModel>;

  abstract getUserFirebase(firebaseUid : string): Observable<UserModel>;

  abstract postRefundRequest(userID:string,refundForm:File,supports: File[],description:string): Observable<UserModel>;

}
