import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { ActionButtonComponent } from 'src/presentation/app/shared/components/action-button/action-button.component';

@Component({
  selector: 'app-benefits-landing',
  templateUrl: './benefits-landing.component.html',
  styleUrls: ['./benefits-landing.component.css'],
  standalone: true,
  imports: [CommonModule, RouterModule, ActionButtonComponent],
})
export class BenefitsLandingComponent implements OnInit, AfterViewInit {
  @ViewChild('benefitsContent') benefitsContent!: ElementRef;

  constructor() {}

  ngOnInit(): void {
    this.setActiveDot(0);
  }

  ngAfterViewInit(): void {
    const dots = document.querySelectorAll('.dot');
    const benefitsContent = this.benefitsContent.nativeElement;

    dots.forEach((dot, index) => {
      dot.addEventListener('click', () => {
        const liWidth = benefitsContent.querySelector('li').offsetWidth;
        benefitsContent.scrollTo({
          left: index * liWidth,
          behavior: 'smooth',
        });
        this.setActiveDot(index);
      });
    });

    benefitsContent.addEventListener('scroll', () => {
      const scrollPercentage =
        benefitsContent.scrollLeft /
        (benefitsContent.scrollWidth - benefitsContent.clientWidth);
      const dotPosition = scrollPercentage * (dots.length - 1);
      const closestDotIndex = Math.round(dotPosition);

      this.setActiveDot(closestDotIndex);
    });
  }

  setActiveDot(index: number): void {
    const dots = document.querySelectorAll('.dot');
    dots.forEach((dot) => dot.classList.remove('active'));
    dots[index].classList.add('active');
  }
}
