import { Observable } from 'rxjs';

import { UseCase } from 'src/domain/base/use-case';
import { PlanContractModel } from 'src/domain/models/plan-contract/plan-contract.model';
import { PlanContractRepository } from 'src/domain/repositories/plan-contract.repository';

export class GetPlanContractUseCase
  implements UseCase<{ planId: string }, PlanContractModel>
{
  constructor(private planContractRepository: PlanContractRepository) {}

  execute(params: { planId: string }): Observable<PlanContractModel> {
    return this.planContractRepository.getPlan(params.planId);
  }
}
