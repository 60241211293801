import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class FooterComponent implements OnInit {
  waveImageUrl = 'footer_wave.png';
  @Input() isResumed = false;

  ngOnInit(): void {
    this.changeWaveImage('footer_wave.png');
  }

  detectDeviceType() {
    if (window.innerWidth <= 530) {
      return 'mobile';
    } else if (window.innerWidth <= 900) {
      return 'tablet';
    } else {
      return 'web';
    }
  }

  changeWaveImage(imageName: string) {
    const deviceType = this.detectDeviceType();

    if (deviceType === 'tablet') {
      this.waveImageUrl = `tablet/${imageName}`;
    } else if (deviceType === 'mobile') {
      this.waveImageUrl = `mobile/${imageName}`;
    } else {
      this.waveImageUrl = `web/${imageName}`;
    }
  }
}
