import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SimulationSource } from '../services/simulation.source';
import { SimulationRepository } from 'src/domain/simulation/repositories/simulation.repositories';
import { ResponseDataPlaceModel } from 'src/domain/simulation/models/place.model';
import { CalculateDataModel } from '../../../domain/simulation/models/send.data.model';
import { ResultsEntity } from 'src/domain/simulation/entities/results.entities';

@Injectable({
  providedIn: 'root',
})
export class SimulationImplementationRepository extends SimulationRepository {
  constructor(private simultionSource: SimulationSource) {
    super();
  }
  override getPlaces(): Observable<ResponseDataPlaceModel> {
    return this.simultionSource.getPlaces();
  }

  override sendDataSimulation(
    userSimulationData: CalculateDataModel
  ): Observable<ResultsEntity> {
    return this.simultionSource.sendDataSimulation(userSimulationData);
  }

  override getAvailableDays(destinationId: string): Observable<number[]> {
    return this.simultionSource.getAvailableDays(destinationId);
  }

  override checkPayment(id: string): Observable<any> {
    return this.simultionSource.checkPayment(id);
  }

  override checkPayment2(id: string): Observable<any> {
    return this.simultionSource.checkPayment2(id);
  }
}
