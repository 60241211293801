import { NgModule, importProvidersFrom } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TravelPlanModule } from './repositories/travel-plan/travel-plan.module';
import { UserModule } from './repositories/user/user.module';
import { CatalogModule } from './repositories/catalog/catalog.module';
import { PlanContractModule } from './repositories/plan-contract/plan-contract.module';
import { ValidateOTPModule } from './repositories/validate-otp/validate-otp.module';

@NgModule({
  providers: [
    importProvidersFrom(
      TravelPlanModule,
      UserModule,
      CatalogModule,
      PlanContractModule,
      ValidateOTPModule
    ),
  ],
  imports: [CommonModule],
})
export class DataModule {}
