import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import {
  CartChargeModel,
  CartChargeResponse,
} from 'src/domain/openpay/models/cart-charge-model';
import { GetStatusTransactionModel } from 'src/domain/openpay/models/get-status-transaction.model';
import { environment } from 'src/environments/environment';
import {
  AUTHENTICATION_DATA_KEY,
  AUTHENTICATION_DEVICE,
  AUTHENTICATION_USER_ID,
} from 'src/presentation/utils/constants/constants';
import { getData } from 'src/presentation/utils/localStorageHandler/localStorageHandler.helper';
import OpenPaySource from '../repositories/openpay.source';
import { AuthService } from 'src/presentation/app/shared/services/auth/auth.service';
import { AuthenticationModel } from 'src/domain/models/auth.model';

@Injectable({
  providedIn: 'root',
})
export class OpenPayService extends OpenPaySource {
  private deviceSessionId: string = '';

  constructor(
    private readonly http: HttpClient,
    private authService: AuthService
  ) {
    super();
  }

  getUserId(): string {
    return getData(AUTHENTICATION_USER_ID);
  }

  async getDeviceSessionId() {
    this.deviceSessionId = (await window.OpenPay?.deviceData?.setup({})) || '';
    if (this.deviceSessionId.length > 0) {
      localStorage.setItem(AUTHENTICATION_DEVICE, this.deviceSessionId);
    }
    return this.deviceSessionId;
  }

  createCartCharge(
    cartCharge: CartChargeModel
  ): Observable<CartChargeResponse> {
    const userId = this.getUserId();
    const endpoints = {
      createCharge: `${environment.openPayUriBase}/Transaction/createCharge?userId=${userId}`,
      getTransationStatus: (id: string) =>
        `${environment.openPayUriBase}/Transaction/GetStatusTransaction?idTransaction=${id}`,
    };
    const headers = this.addTokenHeader();
    cartCharge.device_session_id = this.getUserId().replaceAll('-', '');
    return this.http
      .post<CartChargeResponse>(endpoints.createCharge, cartCharge, {
        headers,
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          if (error.status === 401) {
            return throwError(() => 'Ocurrió un error en el pago');
          }

          return throwError(() =>
            error.message == ''
              ? `Disculpa, ocurrió un error en el pago, por favor, intente más tarde.`
              : error.message
          );
        })
      );
  }

  private addTokenHeader(): HttpHeaders {
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return headers;
  }

  getSTatusTransactions(
    idTransaction: string
  ): Observable<GetStatusTransactionModel> {
    const headers = this.addTokenHeader();
    const userId = this.getUserId();
    const endpoints = {
      createCharge: `${environment.openPayUriBase}/Transaction/createCharge?userId=${userId}`,
      getTransationStatus: (id: string) =>
        `${environment.openPayUriBase}/Transaction/GetStatusTransaction?idTransaction=${id}`,
    };

    return this.http
      .get<GetStatusTransactionModel>(
        endpoints.getTransationStatus(idTransaction),
        {
          headers,
        }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(
            () => 'Ocurrió un error durante la transación en el pago.'
          );
        })
      );
  }
}
