import { Component } from '@angular/core';
import { FooterComponent } from 'src/presentation/app/landing-page/components/footer/footer.component';

@Component({
  selector: 'app-loading',
  standalone: true,
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css'],
})
export class LoadingComponent {}
