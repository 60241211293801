import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TransactionEntity } from 'src/data/repositories/plan-contract/entities/transaction.entity';
import { AdvancesAvailableModel } from 'src/domain/models/advance-available.model';
import { ProductModel } from 'src/domain/models/product.model';
import { TransactionModel } from 'src/domain/models/transaction.model';
import { TravelPlanSummaryModel } from 'src/domain/models/travel-plan-summary.model';
import { GetCatalogAdvancesAvailableUseCase } from 'src/domain/usecases/catalog/get-catalog-advances-available.usecase';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  standalone: true,
  imports: [CommonModule],
})
export class TableAdvanceAvailableComponent implements OnInit {
  constructor(
    private getCatalogAdvancesAvailableUseCase: GetCatalogAdvancesAvailableUseCase
  ) {}
  ngOnInit(): void {
    this.getCatalogAdvancesAvailableUseCase
      .execute({ savingsPlanId: this.product!.savingsPlan.id })
      .subscribe({
        next: (result) => {
          this.advancesAvailable = result.data!;
        },
        error: (err) => {
          this.advancesAvailable = [];
        },
      });
  }
  @Input() product?: ProductModel;
  advancesAvailable: AdvancesAvailableModel[] = [];
}
