import { Injectable, inject } from '@angular/core';
import {
  Auth,
  AuthProvider,
  GoogleAuthProvider,
  UserCredential,
  authState,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RefreshTokenUseCase } from 'src/domain/auth/usecases/refresh-token.usecase';
import { AuthenticationModel } from 'src/domain/models/auth.model';
import {
  AUTHENTICATION_DATA_KEY,
  AUTHENTICATION_DEVICE,
  AUTHENTICATION_USER_ID,
  OPENPAY_TRANSACTION,
} from 'src/presentation/utils/constants/constants';
import {
  getData,
  removeData,
  setData,
} from 'src/presentation/utils/localStorageHandler/localStorageHandler.helper';
import { AuthEventsService } from '../auth-events/auth-events.service';
import { IdleService } from '../idle/idle.service';

export interface Credential {
  email: string;
  password: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private refreshTokenUseCase = inject(RefreshTokenUseCase);
  private router = inject(Router);
  private auth: Auth = inject(Auth);
  private authEventsService: AuthEventsService = inject(AuthEventsService);
  private idleService: IdleService = inject(IdleService);

  readonly authState$ = authState(this.auth);

  constructor() {
    if (this.isAuthenticated()) {
      this.idleService.startMonitoring(); // Iniciar monitoreo de inactividad si el usuario ya está autenticado
      this.idleService.setAuthStatus(true);
    }
    this.authEventsService.logout$.subscribe(() => this.logout());
  }

  signInWithGoogleProvider(): Promise<UserCredential> {
    const provider = new GoogleAuthProvider();
    this.idleService.startMonitoring(); // Iniciar monitoreo de inactividad al iniciar sesión
    this.idleService.setAuthStatus(true);
    return this.callPopUp(provider);
  }

  async callPopUp(provider: AuthProvider): Promise<UserCredential> {
    try {
      const result = await signInWithPopup(this.auth, provider);
      return result;
    } catch (error: any) {
      return error;
    }
  }

  public setAuthData(authData: AuthenticationModel): void {
    setData(AUTHENTICATION_DATA_KEY, { a: btoa(JSON.stringify(authData)) });
    setData(AUTHENTICATION_USER_ID, authData.user.id.toString());
    this.idleService.startMonitoring(); // Iniciar monitoreo de inactividad al establecer datos de autenticación
    this.idleService.setAuthStatus(true);
  }

  public isAuthenticated(): boolean {
    return !!this.getAuthData();
  }

  public getToken(): string {
    return this.getAuthData()?.idToken ?? '';
  }

  public getRefreshToken(): string {
    return this.getAuthData()?.refreshToken ?? '';
  }

  public isTokenExpired(): boolean {
    return this.tokenExpired(this.getToken());
  }

  public logout(): void {
    removeData(AUTHENTICATION_DATA_KEY);
    removeData(AUTHENTICATION_USER_ID);
    removeData(AUTHENTICATION_DEVICE);
    removeData(OPENPAY_TRANSACTION);
    this.router.navigate(['/auth/login']);
  }

  public refreshToken(token: string): Observable<AuthenticationModel> {
    return this.refreshTokenUseCase.execute({ token });
  }

  public getAuthData(): AuthenticationModel | null {
    const authData = getData(AUTHENTICATION_DATA_KEY);

    if (!authData) {
      return null;
    }

    return JSON.parse(atob(authData.a));
  }

  public getAuthToken(): string | null {
    const authData = JSON.parse(
      localStorage.getItem(AUTHENTICATION_DATA_KEY) || ''
    );
    if (!authData) {
      return null;
    }
    return authData.a;
  }

  private tokenExpired(token: string): boolean {
    if (!token) {
      return true;
    }

    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }
}
