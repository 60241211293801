import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Renderer2,
} from '@angular/core';
import comentarios from '../../data/comments/comments';

@Component({
  selector: 'landing-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css'],
  standalone: true,
  imports: [CommonModule],
})
export class CommentsComponent implements OnInit, AfterViewInit {
  comentarios: any[] = [];
  @ViewChild('contentComments') contentComments!: ElementRef;
  activeDotIndex: number = 0;

  constructor(private renderer: Renderer2) {}
  ngOnInit(): void {
    this.comentarios = comentarios;
  }
  ngAfterViewInit(): void {
    this.renderer.listen(this.contentComments.nativeElement, 'scroll', () => {
      this.handleScroll();
    });
  }

  handleScroll(): void {
    const container = this.contentComments.nativeElement;
    const scrollLeft = container.scrollLeft;
    const containerWidth = container.offsetWidth;
    const contentWidth = container.scrollWidth;

    const visibleStart = scrollLeft;
    const visibleEnd = scrollLeft + containerWidth;
    const commentWidth = contentWidth / this.comentarios.length;

    const startIndex = Math.floor(visibleStart / commentWidth);
    const endIndex = Math.ceil(visibleEnd / commentWidth);

    if (scrollLeft === 0) {
      this.activeDotIndex = 0;
    } else if (scrollLeft + containerWidth >= contentWidth) {
      this.activeDotIndex = this.comentarios.length - 1;
    } else {
      this.activeDotIndex = Math.floor((startIndex + endIndex) / 2);
    }
  }

  scrollToComment(index: number): void {
    const commentWidth =
      this.contentComments.nativeElement.querySelector('.comment').offsetWidth;

    this.contentComments.nativeElement.scrollTo({
      left: index * commentWidth,
      behavior: 'smooth',
    });
    this.activeDotIndex = index;
  }

  changeDot(index: number): void {
    const lastIndex = 1;

    if (index < 0) {
      this.activeDotIndex = lastIndex;
    } else if (index > lastIndex) {
      this.activeDotIndex = 0;
    } else {
      this.activeDotIndex = index;
    }

    const commentWidth =
      this.contentComments.nativeElement.querySelector('.comment').offsetWidth;
    this.contentComments.nativeElement.scrollTo({
      left: this.activeDotIndex * commentWidth,
      behavior: 'smooth',
    });
  }
}
