<div class="allies pb-2 overflow-hidden mt-md-0">
  <div class="container">
    <div class="row align-items-center pb-3 pb-md-4">
        <div class="col-md-12 col-lg-3 mb-4 pb-3 mb-lg-0 pb-lg-0">
            <p class="text-center text-lg-start my-0 mx-auto mx-lg-0">
              Viaja con los mejores, con nuestros aliados podrás redimir tu ahorro
            </p>
        </div>
        <div class="col-md-4 col-lg-3 mb-4 mb-md-0">
            <a href="https://www.viajescircular.com.co/" target="_blank">
                <img src="\assets\images\landing\banner\Circular.png" alt="Aliado 1" class="img-fluid">
            </a>
        </div>
        <div class="col-md-4 col-lg-3 mb-4 mb-md-0">
          <a href="https://www.onvacation.com/" target="_blank">
              <img src="\assets\images\landing\banner\Vacation.png" alt="Aliado 2" class="img-fluid">
          </a>
        </div>
        <div class="col-md-4 col-lg-3 mb-4 mb-md-0">
            <a href="https://oxohotel.com/" target="_blank">
                <img src="\assets\images\landing\banner\Hotel.png" alt="Aliado 3" class="img-fluid">
            </a>
        </div>
    </div>
  </div>
</div>
