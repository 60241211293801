import { Component, Input } from '@angular/core';
import { AlertsService } from '../../services/alerts/alerts.service';

@Component({
  selector: 'shared-alert-error',
  templateUrl: './alert-error.component.html',
  styleUrls: ['./alert-error.component.css'],
})
export class AlertErrorComponent {
  public alertState = { show: false, text: '', backgroundColor: '', icon: '' };

  constructor(private alertService: AlertsService) {
    this.alertService.alertState$.subscribe((state) => {
      this.alertState = state;
    });
  }

  closeAlert() {
    this.alertService.hideAlert();
  }
}
