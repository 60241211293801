<div class="registration-alert gap-3" *ngIf="alertState.show" [style.background]="alertState.backgroundColor">
  <div class="icon-container">
    <span class="cvu-icon" [class]="alertState.icon"></span>
  </div>
  <p class="text-alert">
    {{ alertState.text }}
  </p>
  <button class="close-button" (click)="closeAlert()">
    <span class="cvu-icon cvu-icon-close"></span>
  </button>
</div>