<div class="header" [style.backgroundImage]="'url(../../../../../assets/images/landing/banner/' + headerImageUrl + ')'">
  <div class="navbar-container">
    <nav class="navbar">
      <div class="logo"><img src="\assets\icons\cvu-logo-landing.svg" alt="CVU logo" /></div>
      <div class="buttons" *ngIf="isLogged; else notLogged">
        <div class="button-sesion" [routerLink]="['/account']">
          <button>
            Ir a mi perfil
          </button>
        </div>
      </div>
      <ng-template #notLogged>
        <div class="buttons">
          <a class="btn-reg" routerLink="/auth/register">Regístrate</a>
          <div class="button-sesion" [routerLink]="['/auth/login']">
            <button>
              Iniciar sesión
            </button>
          </div>
        </div>
      </ng-template>

    </nav>
  </div>

  <div class="container banner">
    <div class="content-banner">
      <p class="plan-de-ahorro">
        Inicia tu <span>plan de ahorro hoy</span> y viaja sin preocuparte
      </p>

      <div class="button-simulation" [routerLink]="['/simulator']">
        <button class="white-button">
          Realiza una simulación </button>

      </div>
    </div>
  </div>
  <div class="container-options-banner">

    <div class="options-banner px-3 px-0 gap-3 gap-md-4">
      <div>
        <div class="option option1" (click)="changeHeaderImage('banner2.png')">
          <span>Libertad y flexibilidad</span>
          <div class="option-overlay"></div>
        </div>
      </div>
      <div>
        <div class="option option2" (click)="changeHeaderImage('banner3.png')">
          <span>Cumplimiento de metas</span>
          <div class="option-overlay"></div>
        </div>
      </div>
      <div>
        <div class="option option3" (click)="changeHeaderImage('banner4.png')">
          <span>Planeación y programación</span>
          <div class="option-overlay"></div>
        </div>
      </div>
    </div>


  </div>
  <div class="banner-overlay"></div>
  <div class="banner-overlay-left"></div>

</div>
