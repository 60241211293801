import { Mapper } from 'src/base/utils/mapper';

import { PaymentSummaryEntity } from '../entities/payment-summary.entity';
import { PaymentSummaryModel } from 'src/domain/models/payment-summary.model';

export class PaymentSummaryMapper extends Mapper<
  PaymentSummaryEntity,
  PaymentSummaryModel
> {
  override mapFrom(param: PaymentSummaryEntity): PaymentSummaryModel {
    return {
     date:param.date,
     number:param.number,
     price:param.price,
     administrationFee : param.administrationFee,
     administrationFeeTaxes : param.administrationFeeTaxes,
     totalPriceToPay:param.totalPriceToPay,
    };
  }

  override mapTo(param: PaymentSummaryModel): PaymentSummaryEntity {
    return {
      date:param.date,
      number:param.number,
      price:param.price,
      administrationFee : param.administrationFee,
      administrationFeeTaxes : param.administrationFeeTaxes,
      totalPriceToPay:param.totalPriceToPay,
     };
  }
}
