import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCreditCardFormat]',
})
export class CreditCardFormatDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: InputEvent) {
    let input = (event.target as HTMLInputElement).value;

    // Eliminar caracteres no numéricos
    input = input.replace(/\D/g, '');

    // Agrupar números de a 4 con espacio
    input = input.replace(/(\d{4})(?=\d)/g, '$1 ');

    // Limitar a 16 caracteres
    input = input.substring(0, 19);

    this.el.nativeElement.value = input;
  }
}
