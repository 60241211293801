import { Observable } from 'rxjs';
import { UseCase } from 'src/domain/base/use-case';

import { Injectable, inject } from '@angular/core';
import { SimulationRepository } from '../repositories/simulation.repositories';

@Injectable({
  providedIn: 'root',
})
export class CheckPaymentCase implements UseCase<string, any> {
  constructor(private simulationRepository: SimulationRepository) {}

  execute(id: string): Observable<any> {
    return this.simulationRepository.checkPayment(id);
  }
}
