import { Mapper } from 'src/base/utils/mapper';
import { TravelPlanSummaryModel } from 'src/domain/models/travel-plan.model';
import { PlanContractSummaryEntity } from '../entities/plan-contract-summary.entity';

export class PlanContractSummaryMapper extends Mapper<
  PlanContractSummaryEntity,
  TravelPlanSummaryModel
> {
  override mapFrom(param: PlanContractSummaryEntity): TravelPlanSummaryModel {

    return {
      id: param.id,
      destination: {
        id: '',
        name: param.productName,
        image: '',
        description: '',
      },
      numberContributions: param.numberContributions,
      monthlyContributionPrice: param.monthlyContributionPrice,
      contributionsMade: param.contributionsMade,
      totalSaving: param.valueSaved,
      totalBalance:param.planValue,
      nextContributionDate: param.nextContributionDate.toString(),
      lastContributionAt: param.lastContributionDate,
      };
  }

  override mapTo(param: TravelPlanSummaryModel): PlanContractSummaryEntity {
    throw new Error('Method not implemented.');
  }
}
