import { Mapper } from 'src/base/utils/mapper';
import { TravelPlanSummaryModel } from 'src/domain/models/travel-plan.model';
import { TravelPlanSummaryEntity } from '../entities/travel-plan-summary-entity';

export class TravelPlanSummaryMapper extends Mapper<
  TravelPlanSummaryEntity,
  TravelPlanSummaryModel
> {
  override mapFrom(param: TravelPlanSummaryEntity): TravelPlanSummaryModel {
    return { ...param, lastContributionAt: new Date(param.lastContributionAt) };
  }

  override mapTo(param: TravelPlanSummaryModel): TravelPlanSummaryEntity {
    throw new Error('Method not implemented.');
  }
}
