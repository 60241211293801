import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ValidateOtpRepository } from 'src/domain/repositories/validate-otp.repository';
import { ConfirmOtpUsecase } from 'src/domain/usecases/validate-otp/confirm-otp.usecase';
import { SendOtpUsecase } from 'src/domain/usecases/validate-otp/send-otp.usecase';
import { ValidateOtpImplRepository } from './validate-otp-impl.repository';

const sendOtpUseCaseFactory = (validateOtpRepository: ValidateOtpRepository) =>
  new SendOtpUsecase(validateOtpRepository);

const sendOtpUseCaseProvider = {
  provide: SendOtpUsecase,
  useFactory: sendOtpUseCaseFactory,
  deps: [ValidateOtpRepository],
};

const confirmOtpUseCaseFactory = (
  validateOtpRepository: ValidateOtpRepository
) => new ConfirmOtpUsecase(validateOtpRepository);

const confirmOtpUseCaseProvider = {
  provide: ConfirmOtpUsecase,
  useFactory: confirmOtpUseCaseFactory,
  deps: [ValidateOtpRepository],
};

const validateOtpRepositoryProvider = {
  provide: ValidateOtpRepository,
  useClass: ValidateOtpImplRepository,
};

@NgModule({
  providers: [
    sendOtpUseCaseProvider,
    confirmOtpUseCaseProvider,
    validateOtpRepositoryProvider,
  ],
  imports: [CommonModule],
})
export class ValidateOTPModule {}
