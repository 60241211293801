export const environment = {
  firebase: {
    projectId: 'cvu-qa',
    appId: '1:216524924964:web:2232feaebc019ea05310b1',
    storageBucket: 'cvu-qa.appspot.com',
    apiKey: 'AIzaSyCPVSCJP529AZOWndsMo__7huke4rWWEEA',
    authDomain: 'cvu-qa.firebaseapp.com',
    messagingSenderId: '216524924964',
  },
  uriBase: 'https://qa.back.cvu.com.co/api/ms-admin/v1/',
  uriOpenData: 'https://www.datos.gov.co/resource/gdxc-w37w.json?$select=',
  openPayUriBase: 'https://qa.payment.cvu.com.co/api',
  openpay: {
    ID: 'maxo7ez48mibgngvymwv',
    privateKey: 'sk_f0c1959ca9214861accf2a4b23495113',
    publicKey: 'pk_e50e2c55b55548dfa72391fb201e8910',
    URL: 'https://sandbox-api.openpay.co/v1/',
  },
};
