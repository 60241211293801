import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-checkbox-icon',
  templateUrl: './checkbox-icon.component.html',
  styleUrls: ['./checkbox-icon.component.css']
})
export class CheckboxComponent {
@Input()
public value: boolean = false;
@Input()
public textCheckbox: string = '';
}
