import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hideEmailPart'
})
export class HideEmailPartPipe implements PipeTransform {
  transform(email: string): string {
    const parts = email.split('@');
    const username = parts[0];
    const domain = parts[1];
    const length = username.length;
    const visibleCharacters = Math.ceil(length / 2);
    const hiddenCharacters = length - visibleCharacters;
    const hiddenCharactersFiller = '*';

    const visiblePart = username.substr(0, visibleCharacters);
    const hiddenPart = hiddenCharactersFiller.repeat(hiddenCharacters);

    return `${visiblePart}${hiddenPart}@${domain}`;
  }
}
