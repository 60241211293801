import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-validate-otp-modal',
  templateUrl: './validate-otp-moda.component.html',
  styleUrls: ['./validate-otp-moda.component.css'],
})
export class ValidateOtpModalComponent {
  @Input() isLoading: boolean = false;
  @Input() isError: boolean = false;
  @Input() isReSent: boolean = false;
  @Input() isErrorReSent: boolean = false;
  validationCode: string = '';
  resendTimer: number = 30;
  canResend: boolean = false;

  @Output() close = new EventEmitter<void>();
  @Output() validate = new EventEmitter<string>();
  @Output() resendCode = new EventEmitter<void>();

  ngOnInit() {
    this.startResendTimer();
  }

  closeModal() {
    this.close.emit();
  }

  changeText(event: any) {
    const text = event.target.value;
    this.validationCode = text;
  }

  validateCode() {
    this.validate.emit(this.validationCode);
  }

  startResendTimer() {
    this.resendTimer = 30;
    this.canResend = false;
    const intervalId = setInterval(() => {
      this.resendTimer--;
      if (this.resendTimer <= 0) {
        clearInterval(intervalId);
        this.canResend = true;
      }
    }, 1000);
  }

  onResendCode() {
    this.resendCode.emit();
    this.startResendTimer();
  }
}
