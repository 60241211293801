<div class="container container-comments">
  <h1 class="title-comments mb-5 pb-2">Más de 50 años acompañando a los colombianos</h1>
  <div class="position-relative">
    <div class="right-decoration position-absolute d-none d-md-block"></div>
    <div class="arrow-left" (click)="changeDot(activeDotIndex - 1)">
      <img src="../../../../../assets/images/landing/icon/arrow-left.png" alt="">
    </div>
    <div class="arrow-right" (click)="changeDot(activeDotIndex + 1)">
      <img src="../../../../../assets/images/landing/icon/arrow-right.png" alt="">
    </div>
  
    <div class="container-content">
      <div class="content-comments" #contentComments style="overflow-x: auto;">
        <li class="comment" *ngFor="let item of comentarios; let i = index" id="comment{{i}}">
          <div class="custom-shape">
            <img class="custom-image" src="{{item.foto}}" alt="">
            <div class="info-comment">
              <div class="name">{{item.name}}</div>
              <div class="destination">Viajó a {{item.destination}}</div>
            </div>
          </div>
          <div class="text-comment pt-5">
            <div class="quotes-open"><img src="../../../../../assets/images/landing/comments/quote-open.png" alt=""></div>
            {{item.comment}}
            <div class="quotes-close"><img src="../../../../../assets/images/landing/comments/quote-close.png" alt="">
            </div>
          </div>
        </li>
      </div>
      <div class="dots-container my-2 my-md-3 my-lg-5">
        <span class="dot-comments web" [class.active]="activeDotIndex === 0" (click)="scrollToComment(0)"></span>
        <span class="dot-comments web" [class.active]="activeDotIndex === 3" (click)="scrollToComment(3)"></span>
        <div class="mobile">
          <span class="dot-comments" *ngFor="let comentario of comentarios; let i = index" (click)="scrollToComment(i)"
            [class.active]="activeDotIndex === i"></span>
        </div>
      </div>
    </div>
  </div>
</div>