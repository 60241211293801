import { Component, EventEmitter, Input, Output } from '@angular/core';

const ratingMeanings = ['Sin calificación', 'Pobre', 'Regular', 'Buena', 'Muy Buena', 'Excelente'];

@Component({
  selector: 'shared-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.css']
})
export class StarRatingComponent {
  @Input() rating = 0;
  @Output() ratingChange = new EventEmitter<number>();

  ratingMeaning = '';

  maxStars = 5;
  starRange: number[] = [];

  constructor() {
    this.starRange = Array.from({ length: this.maxStars }, (_, i) => i + 1);
  }

  setRating(rating: number): void {
    this.rating = rating;
    this.ratingChange.emit(this.rating);
    this.calculateRatingMeaning();
  }

  calculateRatingMeaning(): void {
    this.ratingMeaning = ratingMeanings[this.rating] || 'Calificación inválida';
  }
}
