import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginUseCase } from './usecases/login.usecase';
import { AuthRepository } from './repositories/auth.repository';
import { AuthImplementationRepository } from 'src/data/auth/repositories/auth.repository-impl';
import AuthSource from 'src/data/auth/services/auth.source';
import { AuthService } from 'src/data/auth/services/auth.services';
import { HttpClientModule } from '@angular/common/http';
import { RegisterUseCase } from './usecases/register.usecase';
import { RefreshTokenUseCase } from './usecases/refresh-token.usecase';

const loginUseCaseFactory = () => new LoginUseCase();
const registerUseCaseFactory = () => new RegisterUseCase();
const refreshTokenUseCaseFactory = () => new RefreshTokenUseCase();

const loginUseCaseProvider = {
  provide: LoginUseCase,
  useFactory: loginUseCaseFactory,
  deps: [AuthRepository],
};

const registerUseCaseProvider = {
  provide: RegisterUseCase,
  useFactory: registerUseCaseFactory,
  deps: [AuthRepository],
};

const refreshTokenUseCaseProvider = {
  provide: RefreshTokenUseCase,
  useFactory: refreshTokenUseCaseFactory,
  deps: [AuthRepository],
};

const authRepositoryProvider = {
  provide: AuthRepository,
  useClass: AuthImplementationRepository,
};

const authSourceProvider = {
  provide: AuthSource,
  useClass: AuthService,
};

@NgModule({
  providers: [
    loginUseCaseProvider,
    registerUseCaseProvider,
    refreshTokenUseCaseProvider,
    authRepositoryProvider,
    authSourceProvider,
  ],
  imports: [CommonModule, HttpClientModule],
})
export class AuthDomainModule {}
