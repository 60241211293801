
export enum TransactionType{
  Contribution='Contribution',
  Advance='Advance'
}


export interface TransactionModel {
  type:      TransactionType;
  amount:    number;
  confirmed: boolean;
  createdAt: Date;
}
