import { Mapper } from 'src/base/utils/mapper';
import { AvailableContractEntity } from '../entities/available-contract.entity';
import { AvailableContractModel } from 'src/domain/models/available-contract.model';

export class AvailableContractForSubscriptionMapper extends Mapper<
  AvailableContractEntity,
  AvailableContractModel
> {
  override mapTo(param: AvailableContractModel): AvailableContractEntity {
    return {
      ...param,
    };
  }
  override mapFrom(param: AvailableContractEntity): AvailableContractModel {
    return {
      ...param,
    };
  }
}
