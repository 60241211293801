import { Mapper } from 'src/base/utils/mapper';

import { UserModel } from 'src/domain/models/user.model';
import { UserEntity } from '../entities/user-entity';

export class UserMapper extends Mapper<
  UserEntity,
  UserModel
> {
  override mapTo(param: UserModel): UserEntity {
    return {
      ...param,
    };
  }
  override mapFrom(param: UserEntity): UserModel {
    return {
      ...param,
    };
  }
}
