import { Observable } from 'rxjs';
import { PaginatedData } from 'src/base/wrappers/paginated-data';

import { UseCase } from 'src/domain/base/use-case';
import { CatalogRepository } from 'src/domain/repositories/catalog.repository';
export class GetCatalogContributionAvailableForSubscriptionUseCase
  implements UseCase<{ planId: string }, PaginatedData<number>>
{
  constructor(private catalogRepository: CatalogRepository) {}

  execute(): Observable<PaginatedData<number>> {
    return this.catalogRepository.getCatalogContributionAvailableForSubscription();
  }
}

