import { Injectable } from '@angular/core';
import * as htmlToImage from 'html-to-image';
import { Options } from 'html-to-image/lib/types';

@Injectable({
  providedIn: 'root',
})
export class CreateImageService {
  constructor() {}

  async createImageFromHTML(
    element: HTMLElement,
    name: string
  ): Promise<File | null> {
    let image = null;
    const options: Options = {
      quality: 1,
      filter: (node) => !node.classList?.contains('destintation-img'),
    };

    try {
      await htmlToImage.toJpeg(element, options);
      await htmlToImage.toJpeg(element, options);
      await htmlToImage.toJpeg(element, options);
      const dataUrl = await htmlToImage.toJpeg(element, options);
      const res: Response = await fetch(dataUrl);
      const blob: Blob = await res.blob();

      image = new File([blob], name, { type: 'image/jpeg' });
    } catch (error) {
      console.error('Error', error);
    }

    return image;
  }
}
