import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthEventsService {
  private loginEvent = new Subject<void>();
  private logoutEvent = new Subject<void>();

  login$ = this.loginEvent.asObservable();
  logout$ = this.logoutEvent.asObservable();

  emitLogin() {
    this.loginEvent.next();
  }
  emitLogout() {
    this.logoutEvent.next();
  }
}
