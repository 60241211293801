import { LOCALE_ID, NgModule, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeEsCO from '@angular/common/locales/es-CO';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { ResultsComponent } from './simulation/pages/results/results.component';
import { DataModule } from 'src/data/data.module';
import { HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
import { AuthInterceptor } from './shared/interceptors/auth/auth.interceptor';
import { AuthDomainModule } from 'src/domain/auth/auth-domain.module';
import { ResultCardComponent } from './simulation/pages/results/components/result-card/result-card.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { ROOT_REDUCERS } from './store/app.state';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { ActionButtonComponent } from './shared/components/action-button/action-button.component';
import { FooterComponent } from './landing-page/components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { DraftCardsComponent } from './account/pages/draft-details/components/draft-cards/draft-cards.component';
import { ToolTipComponent } from "./shared/components/tool-tip/tool-tip.component";
import { SessionTimeoutDialogComponent } from './shared/components/session-timeout-dialog/session-timeout-dialog.component';
import { IdleService } from './shared/services/idle/idle.service';
import { OpenPayDomainModule } from 'src/domain/openpay/openpay-domain.module';


registerLocaleData(localeEsCO);

@NgModule({
  declarations: [
    AppComponent,
    ResultsComponent,
    SessionTimeoutDialogComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    StoreModule.forRoot(ROOT_REDUCERS, {}),
    EffectsModule.forRoot([]),
    AppRoutingModule,
    RouterModule,
    SharedModule,
    AuthDomainModule,
    ResultCardComponent,
    ActionButtonComponent,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    AngularFireModule.initializeApp(environment.firebase),
    LoaderComponent,
    FooterComponent,
    ToolTipComponent,
    DraftCardsComponent,
    OpenPayDomainModule
  ],
  providers: [
    provideHttpClient(),
    { provide: LOCALE_ID, useValue: 'es-CO' },
    importProvidersFrom(DataModule),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    IdleService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
