<div class="simulator-container">
  <shared-header textHeader="" [backRouter]="backRoute"></shared-header>
  <div class="container d-flex justify-content-end">
    <div class="form-container px-1 px-md-0">
      <form [formGroup]="simulationForm" class="position-relative">
        <h1 class="title mt-2 mt-lg-0 mb-3 mb-md-4 text-sm-center">Planea tu próxima aventura</h1>
        <h2 class="subtitle text-sm-center mb-3 mb-md-5 pb-0 pb-lg-2">
          Conoce el valor aproximado que debes ahorrar para tu próximo viaje
        </h2>
        <!-- Formulario -->
        <div class="ctn-select">
          <div class="h3-drp">
            <p>Destino</p>
          </div>
          <select
            id="Destino"
            required
            formControlName="destinationId"
            class="custom-select"
            (change)="onDestinationChange()"
          >
            <option value="" disabled selected>
              Elige la ciudad que deseas visitar
            </option>
            <ng-container *ngFor="let item of placeList">
              <option [value]="item.id">{{ item.name }}</option>
            </ng-container>
          </select>
        </div>
        <div class="ctn-select">
          <div class="h3-drp">
            <p>¿Cuántos días durará tu viaje?</p>
          </div>
          <select id="Dias-Viaje" required formControlName="numberDays" class="custom-select">
            <option value="" disabled selected>Selecciona el numero de días</option>
            <option *ngFor="let option of daysOptions" [value]="option.value">
              {{ option.label }} Día{{option.label != 1 ? 's' : '' }}
            </option>
          </select>
        </div>
        <div class="ctn-select mb-3 pb-0 mb-sm-5 pb-sm-2">
          <div class="h3-drp">
            <p>Número de viajeros</p>
          </div>
          <select id="Numero-Viajeros" required formControlName="numberPeople" class="custom-select">
            <option value="" disabled selected>Selecciona el numero de viajeros</option>
            <option *ngFor="let option of peopleOptions" [value]="option.value">
              {{ option.label }}
            </option>
          </select>
        </div>
        <div class="ctn-btn mb-4 bottom-0">
          <shared-action-button id="Btn-Calcular" (click)="onSubmit()" [disabled]="simulationForm.invalid" textButton="Calcular" maxWidth="100%"></shared-action-button>
        </div>
      </form>
    </div>
  </div>
  <div class="bg-decoration d-sm-none"></div>
</div>
<div class="loading-screen position-fixed top-0 start-0 end-0 bottom-0" *ngIf="loading">
  <app-loading></app-loading>
</div>
