import { Component } from '@angular/core';
import { IdleService } from './shared/services/idle/idle.service';

declare global {
  interface Window {
    OpenPay: any;
  }
}
declare var OpenPay: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'CVU';
  deviceSessionId: string = '';

  constructor(private idleService: IdleService) {
    OpenPay.setId('maxo7ez48mibgngvymwv');
    OpenPay.setApiKey('pk_e50e2c55b55548dfa72391fb201e8910');
    OpenPay.getSandboxMode(true);
    window.OpenPay = { OpenPay } as any;
  }
}
