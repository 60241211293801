import { Observable } from 'rxjs';
import { UseCase } from 'src/domain/base/use-case';

import { Injectable, inject } from '@angular/core';

import { AuthRepository } from '../repositories/auth.repository';
import { AuthModel } from '../models/auth-model';

@Injectable({
  providedIn: 'root',
})
export class RegisterUseCase implements UseCase<AuthModel, boolean> {
  authRepository = inject(AuthRepository);
  execute(authModel: AuthModel): Observable<boolean> {
    return this.authRepository.register(authModel);
  }
}
