import { Observable } from 'rxjs';

import { UseCase } from 'src/domain/base/use-case';
import { UserFirebaseModel } from 'src/domain/models/user-firebase.model';
import { UserModel } from 'src/domain/models/user.model';
import { UserRepository } from 'src/domain/repositories/user.repository';
export class PostUserFirebaseUseCase
  implements UseCase<{ userFirebase:UserFirebaseModel}, UserModel>
{
  constructor(private userRepository: UserRepository) {}

  execute(params: {userFirebase:UserFirebaseModel}): Observable<UserModel> {
    return this.userRepository.postUserFirebase(params.userFirebase);
  }
}
