<div class="ctn-text">
  <div class="ctn-info">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <h1 class="subtitle">Se uno de los <strong>MILES</strong> de colombianos que han preferido ahorrar para viajar.</h1>
  </div>
  <div id="bottom-image-wrapper">
    <img id="bottom-image" src="assets\backgrounds\loadingBG.png" alt="Decoration">
  </div>
</div>
