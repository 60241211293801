import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GetPlanContractUseCase } from 'src/domain/usecases/plan-contract/get-plan.usecase';
import { PlanContractRepository } from 'src/domain/repositories/plan-contract.repository';
import { PlanContractImplRepository } from './plan-contract-impl.repository';
import { GetPlansContractUseCase } from 'src/domain/usecases/plan-contract/get-plans.usecase';
import { GetPaymentSummaryUseCase } from 'src/domain/usecases/plan-contract/get-payment-summary.usecase';
import { PostTransactionContributionUseCase } from 'src/domain/usecases/plan-contract/post-transaction-contribution.usecase';
import { GetPlansContractSummaryUseCase } from 'src/domain/usecases/plan-contract/get-plans-summary.usecase';
import { GetTransationsListUseCase } from 'src/domain/usecases/plan-contract/get-transactions-list.usecase';
import { GetAvailableContractForContributionUseCase } from 'src/domain/usecases/plan-contract/get-available-contract-for-contribution.usecase';
import { GetAvailableContractForSubscriptionUseCase } from 'src/domain/usecases/plan-contract/get-available-contract-for-subscription.usecase';

const getPlanContractUseCaseFactory = (
  planContractRepository: PlanContractRepository
) => new GetPlanContractUseCase(planContractRepository);

const getPlanContractUseCaseProvider = {
  provide: GetPlanContractUseCase,
  useFactory: getPlanContractUseCaseFactory,
  deps: [PlanContractRepository],
};

const getPlansContractUseCaseFactory = (
  planContractRepository: PlanContractRepository
) => new GetPlansContractUseCase(planContractRepository);

const getPlansContractUseCaseProvider = {
  provide: GetPlansContractUseCase,
  useFactory: getPlansContractUseCaseFactory,
  deps: [PlanContractRepository],
};

const getPaymentSummaryUseCaseFactory = (
  planContractRepository: PlanContractRepository
) => new GetPaymentSummaryUseCase(planContractRepository);

const getPaymentSummaryUseCaseProvider = {
  provide: GetPaymentSummaryUseCase,
  useFactory: getPaymentSummaryUseCaseFactory,
  deps: [PlanContractRepository],
};

const postTransactionContributionUseCaseFactory = (
  planContractRepository: PlanContractRepository
) => new PostTransactionContributionUseCase(planContractRepository);

const postTransactionContributionUseCaseProvider = {
  provide: PostTransactionContributionUseCase,
  useFactory: postTransactionContributionUseCaseFactory,
  deps: [PlanContractRepository],
};

const getPlansContractSummaryUseCaseFactory = (
  planContractRepository: PlanContractRepository
) => new GetPlansContractSummaryUseCase(planContractRepository);

const getPlansContractSummaryUseCaseProvider = {
  provide: GetPlansContractSummaryUseCase,
  useFactory: getPlansContractSummaryUseCaseFactory,
  deps: [PlanContractRepository],
};

const getTransactionListUseCaseFactory = (
  planContractRepository: PlanContractRepository
) => new GetTransationsListUseCase(planContractRepository);

const getTransactionListUseCaseProvider = {
  provide: GetTransationsListUseCase,
  useFactory: getTransactionListUseCaseFactory,
  deps: [PlanContractRepository],
};

const getAvailableContractForSubscriptionUseCaseFactory = (
  planContractRepository: PlanContractRepository
) => new GetAvailableContractForSubscriptionUseCase(planContractRepository);

const getAvailableContractForSubscriptionUseCaseProvider = {
  provide: GetAvailableContractForSubscriptionUseCase,
  useFactory: getAvailableContractForSubscriptionUseCaseFactory,
  deps: [PlanContractRepository],
};


const getAvailableContractForContributionUseCaseFactory = (
  planContractRepository: PlanContractRepository
) => new GetAvailableContractForContributionUseCase(planContractRepository);

const getAvailableContractForContributionUseCaseProvider = {
  provide: GetAvailableContractForContributionUseCase,
  useFactory: getAvailableContractForContributionUseCaseFactory,
  deps: [PlanContractRepository],
};

const planContractRepositoryProvider = {
  provide: PlanContractRepository,
  useClass: PlanContractImplRepository,
};

@NgModule({
  providers: [
    getPlanContractUseCaseProvider,
    getPlansContractUseCaseProvider,
    getPaymentSummaryUseCaseProvider,
    postTransactionContributionUseCaseProvider,
    getPlansContractSummaryUseCaseProvider,
    getTransactionListUseCaseProvider,
    getAvailableContractForSubscriptionUseCaseProvider,
    getAvailableContractForContributionUseCaseProvider,
    planContractRepositoryProvider,
  ],
  imports: [CommonModule],
})
export class PlanContractModule {}
