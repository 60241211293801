import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { CommonModule } from '@angular/common';
import { NavBarComponent } from './components/header/header.component';
import { BenefitsLandingComponent } from './components/benefits-landing/benefits-landing.component';
import { CommentsComponent } from './components/comments/comments.component';
import { QuestionsComponent } from './components/questions/questions.component';
import { WorkStepsLandingComponent } from './components/work-steps-landing/work-steps-landing.component';
import { AlliesComponent } from './components/allies/allies.component';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css'],
  standalone: true,
  imports:[CommonModule, FooterComponent,NavBarComponent, BenefitsLandingComponent,CommentsComponent,QuestionsComponent,WorkStepsLandingComponent, AlliesComponent],
})
export class LandingPageComponent {

}
