import { Observable } from 'rxjs';

import { UseCase } from 'src/domain/base/use-case';
import { UserRepository } from 'src/domain/repositories/user.repository';
export class GetCertificationByTypeUseCase
  implements UseCase<{ idUser: string, typeCertification:string }, any>
{
  constructor(private userRepository: UserRepository) {}

  execute(params: { idUser: string, typeCertification:string }): Observable<any> {
    return this.userRepository.getCertificationByType(params.idUser, params.typeCertification);
  }
}
