import { Observable } from 'rxjs';
import { UseCase } from 'src/domain/base/use-case';
import { Injectable, inject } from '@angular/core';
import { AuthenticationModel } from 'src/domain/models/auth.model';
import {
  CartChargeModel,
  CartChargeResponse,
} from '../models/cart-charge-model';
import { OpenPayRepository } from '../repositories/openpay.repository';

@Injectable({
  providedIn: 'root',
})
export class CartChargeUseCase
  implements UseCase<CartChargeModel, CartChargeResponse>
{
  authRepository = inject(OpenPayRepository);

  execute(cartChargeModel: CartChargeModel): Observable<CartChargeResponse> {
    return this.authRepository.createCartCharge(cartChargeModel);
  }
}
