import { Mapper } from 'src/base/utils/mapper';
import { TravelPlanEntity } from '../entities/travel-plan-entity';
import {
  TravelPlanModel,
  TravelPlanState,
} from 'src/domain/models/travel-plan.model';

export class TravelPlanMapper extends Mapper<
  TravelPlanEntity,
  TravelPlanModel
> {
  override mapFrom(param: TravelPlanEntity): TravelPlanModel {
    return {
      ...param,
      state: TravelPlanState[param.state as keyof typeof TravelPlanState],
    };
  }

  override mapTo(param: TravelPlanModel): TravelPlanEntity {
    throw new Error('Method not implemented.');
  }
}
