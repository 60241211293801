<body>
  <app-header></app-header>

  <main>
    <app-benefits-landing></app-benefits-landing>
    <app-work-steps-landing></app-work-steps-landing>
    <app-allies></app-allies>
    <landing-comments></landing-comments>

    <landing-questions></landing-questions>
  </main>

  <app-footer></app-footer>
</body>
