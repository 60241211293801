import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { UserRepository } from 'src/domain/repositories/user.repository';
import { ResponseData } from 'src/base/wrappers/response-data';
import { UserMapper } from './mappers/user-data.mapper';
import { UserModel } from 'src/domain/models/user.model';
import { UserEntity } from './entities/user-entity';
import { ProfileModel } from 'src/domain/models/profile.model';
import { ProfileMapper } from './mappers/profile-data.mapper';
import { ProfileEntity } from './entities/profile-entity';
import { UserFirebaseMapper } from './mappers/user-firebase-data.mapper';
import { UserFirebaseModel } from 'src/domain/models/user-firebase.model';



@Injectable({
  providedIn: 'root',
})
export class UserImplRepository extends UserRepository {




  private readonly userUrl: string;
  private userMapper= new UserMapper();
  private userFirebaseMapper= new UserFirebaseMapper();

  private profileMapper=new ProfileMapper();
  constructor(private http: HttpClient) {
    super();
    this.userUrl = `${environment.uriBase}User`;
  }

  override getCertificationByType(idUser: string, typeCertification: string): Observable<any> {

    return this.http
      .get(`${this.userUrl}/${idUser}/certification/${typeCertification}`, { responseType: 'blob' });
  }

  override getUser(idUser: string): Observable<UserModel> {
    return this.http
      .get<ResponseData<UserEntity>>(`${this.userUrl}/${idUser}`)
      .pipe(map((result) =>{

        return this.userMapper.mapFrom(result.data)}));
  }

  override putUser(user: ProfileModel,idUser:string): Observable<ProfileModel> {

    return this.http
    .put<ResponseData<ProfileEntity>>(`${this.userUrl}/${idUser}`, this.profileMapper.mapTo(user))
    .pipe(map((result) => this.profileMapper.mapFrom(result.data)));
}


override postUserFirebase(userFirebase:UserFirebaseModel): Observable<UserModel> {
  return this.http
    .post<ResponseData<UserEntity>>(
      `${this.userUrl}/firebase`,
      this.userFirebaseMapper.mapTo(userFirebase)
    )
    .pipe(
      map((result) => {
        return this.userMapper.mapFrom(result.data)
      })
    );
}

override getUserFirebase(firebaseUid: string): Observable<UserModel> {
  return this.http
  .get<ResponseData<UserEntity>>(`${this.userUrl}/firebase/${firebaseUid}`)
  .pipe(map((result) =>{

    return this.userMapper.mapFrom(result.data)}));
}

override postRefundRequest(userID: string, refundForm: File, supports: File[], description: string): Observable<UserModel> {
  const formData = new FormData();
  formData.append('RefundForm', refundForm, 'RefundForm.pdf');
  formData.append('Description', description);

  const headers = new HttpHeaders({
    'Content-Type': 'multipart/form-data' // Establecer el tipo de contenido aquí
  });

  return this.http.post<UserModel>(
    `${this.userUrl}/${userID}/refund-request`,
    formData,
    { headers: headers } // Pasar los encabezados en la opción headers
  );
}
}
