import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductModel } from 'src/domain/models/product.model';

@Component({
  selector: 'app-result-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './result-card.component.html',
  styleUrls: ['./result-card.component.css'],
})
export class ResultCardComponent {
  @Input() index: number = 0;
  @Input() product: ProductModel = {
    numberContributions: 0,
    contributionPrice: 0,
    dailyPrice: 0,
    totalPrice: 0,
    savingsPlan: {
      id: '',
      cvuId: 0,
      name: '',
      description: '',
      numberContributions: 0,
      points: 0,
      contributionPrice: 0,
    },
    contributionAdministrationFee: 0,
    contributionTaxes: 0,
  };

  @Output() selectedProduct = new EventEmitter<number>();

  constructor() {}

  initPlan() {
    this.selectedProduct.emit(this.index);
  }

  openAccordionIndex: number = -1;

  toggleAccordion(index: number) {
    if (this.openAccordionIndex === index) {
      this.openAccordionIndex = -1; // Si el mismo acordeón se hace clic de nuevo, ciérralo
    } else {
      this.openAccordionIndex = index;
    }
  }
}
