import { Mapper } from 'src/base/utils/mapper';


import { TransactionEntity } from '../entities/transaction.entity';
import { TransactionModel } from 'src/domain/models/transaction.model';
import { TransactionType } from '../../../../domain/models/transaction.model';

export class TransactionMapper extends Mapper<
  TransactionEntity,
  TransactionModel
> {
  override mapTo(param: TransactionModel): TransactionEntity {
    throw new Error('Method not implemented.');
  }
  override mapFrom(param: TransactionEntity): TransactionModel {
    return {

      type: TransactionType[param.type as keyof typeof TransactionType],
      amount: param.value,
      confirmed: param.confirmed,
      createdAt: new Date(param.date),

    };
  }


}
