import { createAction, props } from '@ngrx/store';
import { CalculateDataModel } from 'src/domain/simulation/models/send.data.model';

export const setInfoForSimulation = createAction(
  '[Simulator] Set Info For Simulation',
  props<{
    infoForSimulation: CalculateDataModel & { savingsPlanId?: string };
  }>()
);

export const clearInfoForSimulation = createAction(
  '[Simulator] Clear Info For Simulation'
);
