import { Observable } from 'rxjs';
import { ResponseDataPlaceModel } from '../models/place.model';
import { CalculateDataModel } from '../models/send.data.model';
import { ResultsEntity } from '../entities/results.entities';
export abstract class SimulationRepository {
  abstract getPlaces(): Observable<ResponseDataPlaceModel>;

  abstract sendDataSimulation(
    userSimulationData: CalculateDataModel
  ): Observable<ResultsEntity>;

  abstract getAvailableDays(destinationId: string): Observable<number[]>;

  abstract checkPayment(id: string): Observable<any>;

  abstract checkPayment2(id: string): Observable<any>;
}
