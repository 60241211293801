import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ActionButtonComponent } from 'src/presentation/app/shared/components/action-button/action-button.component';
import faqs from '../../data/comments/faqs';

@Component({
  selector: 'landing-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css'],
  standalone: true,
  imports: [CommonModule, RouterModule, ActionButtonComponent],
})
export class QuestionsComponent implements OnInit {
  faqs: any[] = [];
  column1: any[] = [];
  column2: any[] = [];
  openedIndex: number | null = null; // Variable para almacenar el índice del acordeón abierto

  ngOnInit(): void {
    this.faqs = faqs;
    const halfLength = Math.ceil(this.faqs.length / 2);
    this.column1 = this.faqs.slice(0, halfLength).map(faq => ({ ...faq, open: false }));
    this.column2 = this.faqs.slice(halfLength).map(faq => ({ ...faq, open: false }));
  }

  toggleAccordion(faq: any, index: number) {
    if (faq.open && this.openedIndex === index) {
      // Si el acordeón ya está abierto y se hace clic en él nuevamente, ciérralo
      faq.open = false;
      this.openedIndex = null;
    } else {
      // Si se hace clic en un nuevo acordeón, cierra el abierto anteriormente y abre el nuevo
      if (this.openedIndex !== null) {
        this.closeAccordion(this.openedIndex);
      }
      faq.open = true;
      this.openedIndex = index;
    }
  }

  // Función para cerrar un acordeón específico
  closeAccordion(index: number) {
    if (index < this.column1.length) {
      this.column1[index].open = false;
    } else {
      this.column2[index - this.column1.length].open = false;
    }
  }

  // Función para procesar el texto y convertir los saltos de línea en etiquetas <br>
  formatText(text: string): string {
    // Reemplaza los saltos de línea dobles por etiquetas <br><br>
    let formattedText = text.replace(/\n{2,}/g, '<br><br>');
    // Reemplaza los saltos de línea individuales por una etiqueta <br>
    formattedText = formattedText.replace(/\n/g, '<br>');
    // Devuelve el texto formateado
    return '<div>' + formattedText + '</div>';
  }
}
