import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { OpenPayRepository } from './repositories/openpay.repository';
import { CartChargeUseCase } from './usecases/create-cart-charge.usecase';
import { OpenPayImplementationRepository } from 'src/data/openpay/repositories/openpay.repository-imp';
import OpenPaySource from 'src/data/openpay/repositories/openpay.source';
import { OpenPayService } from 'src/data/openpay/services/open-pay.service';
import { GetStatusTransactionUsecase } from './usecases/get-status-transaction.usecase';

const cartChargeUseCaseFactory = () => new CartChargeUseCase();

const cartChargeUseCaseProvider = {
  provide: CartChargeUseCase,
  useFactory: cartChargeUseCaseFactory,
  deps: [OpenPayRepository],
};

const OpenPayRepositoryProvider = {
  provide: OpenPayRepository,
  useClass: OpenPayImplementationRepository,
};

const openPaySourceProvider = {
  provide: OpenPaySource,
  useClass: OpenPayService,
};

@NgModule({
  providers: [
    GetStatusTransactionUsecase,
    cartChargeUseCaseProvider,
    OpenPayRepositoryProvider,
    openPaySourceProvider,
  ],
  imports: [CommonModule, HttpClientModule],
})
export class OpenPayDomainModule {}
