import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appExpirationDateFormat]',
})
export class ExpirationDateFormatDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: InputEvent) {
    let input = (event.target as HTMLInputElement).value;

    // Eliminar caracteres no numéricos
    input = input.replace(/\D/g, '');

    // Agrupar números de a 2 con barra diagonal
    input = input.replace(/(\d{2})(?=\d)/g, '$1/');

    // Limitar a 5 caracteres
    input = input.substring(0, 5);

    this.el.nativeElement.value = input;
  }
}
