import { createAction, props } from '@ngrx/store';
import { PaymentSummaryModel } from 'src/domain/models/payment-summary.model';
import { TravelPlanModel } from 'src/domain/models/travel-plan.model';
import { SavingsPlanDataModel } from 'src/domain/savings-plan/models/savings-plan-data.model';

export const setTravelPlan = createAction(
  '[Travel Plan] Set Plan',
  props<{ travelPlan: TravelPlanModel }>()
);

export const removeTravelPlan = createAction('[Travel Plan] Remove Plan');

export const setPaymentTravelPlan = createAction(
  '[Travel Plan] Set Payment Plan',
  props<{ paymentPlan: PaymentSummaryModel }>()
);

export const setTravelPlanSubscriptionInfo = createAction(
  '[Travel Plan] Set Subscription Info',
  props<{ subscriptionInfo: SavingsPlanDataModel }>()
);

export const clearTravelPlanSubscriptionInfo = createAction(
  '[Travel Plan] Clear Subscription Info'
);
