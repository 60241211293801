import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  isLoaderVisible,
  loaderMessage,
} from 'src/presentation/app/store/selectors/loader.selectors';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent {
  isLoading$: Observable<boolean>;
  message$: Observable<string | undefined>;

  constructor(private store: Store) {
    this.isLoading$ = this.store.select(isLoaderVisible);
    this.message$ = this.store.select(loaderMessage);
  }
}
