export default [
  {
    id: 1,
    foto: '../../../../../assets/images/landing/comments/comment1.png',
    name: 'Sofía Parra',
    destination: 'San Francisco',
    comment: 'Gracias a CVU, mi madre y yo pudimos finalmente realizar nuestro viaje soñado a San Francisco. Con su plataforma de ahorro, pudimos planificar y dejar de aplazar esta aventura. Recomendaría CVU a cualquier persona que desee convertir sus sueños en realidad.',
  },
  {
    id: 2,
    foto: '../../../../../assets/images/landing/comments/comment2.png',
    name: 'Sebastián Restrepo',
    destination: 'Cartagena',
    comment: 'Gracias a CVU, mis amigos y yo pudimos finalmente realizar nuestro viaje soñado a Cartagena. Con su plataforma de ahorro, pudimos planificar y dejar de aplazar esta aventura. Recomendaría CVU a cualquier persona que desee convertir sus sueños en realidad.',
  },
  {
    id: 3,
    foto: '../../../../../assets/images/landing/comments/comment3.png',
    name: 'Pablo Caro ',
    destination: 'España',
    comment: 'Gracias a CVU, mi familia y yo pudimos finalmente realizar nuestro viaje soñado a España. Con su plataforma de ahorro, pudimos planificar y dejar de aplazar esta aventura. Recomendaría CVU a cualquier persona que desee convertir sus sueños en realidad.',
  },

  {
    id: 4,
    foto: '../../../../../assets/images/landing/comments/comment4.jpg',
    name: 'Zara Lugo',
    destination: 'San Andrés',
    comment:
      'Gracias a CVU, mis hijos y yo pudimos finalmente realizar nuestro viaje soñado a San Andrés. Con su plataforma de ahorro, pudimos planificar y dejar de aplazar esta aventura. Recomendaría CVU a cualquier persona que desee convertir sus sueños en realidad.',
  },
];
