import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CartChargeModel,
  CartChargeResponse,
} from 'src/domain/openpay/models/cart-charge-model';
import { GetStatusTransactionModel } from 'src/domain/openpay/models/get-status-transaction.model';
import { OpenPayRepository } from 'src/domain/openpay/repositories/openpay.repository';
import OpenPaySource from './openpay.source';

@Injectable({
  providedIn: 'root',
})
export class OpenPayImplementationRepository extends OpenPayRepository {
  constructor(private openpay: OpenPaySource) {
    super();
  }

  override createCartCharge(
    cartCharge: CartChargeModel
  ): Observable<CartChargeResponse> {
    return this.openpay.createCartCharge(cartCharge);
  }

  override getStatusTransactions(
    idTransaction: string
  ): Observable<GetStatusTransactionModel> {
    return this.openpay.getSTatusTransactions(idTransaction);
  }
}
