import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { SimulationRepository } from './repositories/simulation.repositories';
import { GetPlacesUseCase } from './usecases/get-places-useacase';
import { SimulationImplementationRepository } from 'src/data/simulation/repositories/simulation.repository-impl';
import { SimulationSource } from 'src/data/simulation/services/simulation.source';
import { SimulationService } from 'src/data/simulation/services/simulation.services';
import { SendDataSimulationUseCase } from './usecases/post-data-simulation-usecase';
import { GetAvalaibleDaysUseCase } from './usecases/get-available-days-useacase';
import { CheckPaymentCase } from './usecases/check-payment';

const simulationDataUseCaseFactory = (simulationRepo: SimulationRepository) =>
  new GetPlacesUseCase(simulationRepo);

const sendDataSimulationUseCaseFactory = () =>
new  SendDataSimulationUseCase();


  const simulationUseCaseProvider = {
    provide: GetPlacesUseCase,
    useFactory: simulationDataUseCaseFactory,
    deps: [SimulationRepository],
  };

  const getAvailableDaysUseCaseFactory = (simulationRepo: SimulationRepository) =>
new  GetAvalaibleDaysUseCase(simulationRepo);


  const getAvailableDaysUseCaseProvider = {
    provide: GetAvalaibleDaysUseCase,
    useFactory: getAvailableDaysUseCaseFactory,
    deps: [SimulationRepository],
  };
  const simulationRepositoryProvider = {
    provide: SimulationRepository,
    useClass: SimulationImplementationRepository,
  };

  const simulationSourcesProvider = {
    provide: SimulationSource,
    useClass: SimulationService
  };

  const sendDataSimulationUseCaseProvider = {
    provide: SendDataSimulationUseCase,
    useFactory: sendDataSimulationUseCaseFactory,
    deps: [SimulationRepository],
  };

  @NgModule({
    providers: [
      CheckPaymentCase,
      simulationUseCaseProvider,
      simulationRepositoryProvider,
      simulationSourcesProvider,
      getAvailableDaysUseCaseProvider,
      sendDataSimulationUseCaseProvider,
    ],
    imports: [CommonModule, HttpClientModule],
  })
export class SimulationDomainModule {}
