import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/domain/base/use-case';
import { GetStatusTransactionModel } from '../models/get-status-transaction.model';
import { OpenPayRepository } from '../repositories/openpay.repository';

@Injectable({
  providedIn: 'root',
})
export class GetStatusTransactionUsecase
  implements UseCase<string, GetStatusTransactionModel>
{
  authRepository = inject(OpenPayRepository);

  execute(idTransaction: string): Observable<GetStatusTransactionModel> {
    return this.authRepository.getStatusTransactions(idTransaction);
  }
}
