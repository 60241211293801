import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetCatalogDocumentTypesUseCase } from 'src/domain/usecases/catalog/get-catalog-document-types.usecase';
import { CatalogRepository } from 'src/domain/repositories/catalog.repository';
import { CatalogImplRepository } from './catalog-impl.repository';
import { GetCatalogContributionPricesUseCase } from 'src/domain/usecases/catalog/get-catalog-contribution-prices-list.usecase';
import { GetCatalogAdvancesAvailableUseCase } from 'src/domain/usecases/catalog/get-catalog-advances-available.usecase';
import { GetCatalogContributionAvailableForSubscriptionUseCase } from 'src/domain/usecases/catalog/get-catalog-contribution-available-for-subscription.usecase';
import { GetCatalogContributionAvailableForContributionUseCase } from 'src/domain/usecases/catalog/get-catalog-contributions-available-for-contribution.usecase';

const getCatalogDocumentTypesUseCaseFactory = (
  catalogRepository: CatalogRepository
) => new GetCatalogDocumentTypesUseCase(catalogRepository);

const getCatalogDocumentTypesUseCaseProvider = {
  provide: GetCatalogDocumentTypesUseCase,
  useFactory: getCatalogDocumentTypesUseCaseFactory,
  deps: [CatalogRepository],
};


const getCatalogContributionPricesUseCaseFactory = (
  catalogRepository: CatalogRepository
) => new GetCatalogContributionPricesUseCase(catalogRepository);

const getCatalogContributionPricesUseCaseProvider = {
  provide: GetCatalogContributionPricesUseCase,
  useFactory: getCatalogContributionPricesUseCaseFactory,
  deps: [CatalogRepository],
};


const getCatalogAdvanceAvailableUseCaseFactory = (
  catalogRepository: CatalogRepository
) => new GetCatalogAdvancesAvailableUseCase(catalogRepository);

const getCatalogAdvanceAvailableUseCaseProvider = {
  provide: GetCatalogAdvancesAvailableUseCase,
  useFactory: getCatalogAdvanceAvailableUseCaseFactory,
  deps: [CatalogRepository],
};


const getCatalogContributionAvailableForSubscriptionUseCaseFactory = (
  catalogRepository: CatalogRepository
) => new GetCatalogContributionAvailableForSubscriptionUseCase(catalogRepository);

const getCatalogContributionAvailableForSubscriptionUseCaseProvider = {
  provide: GetCatalogContributionAvailableForSubscriptionUseCase,
  useFactory: getCatalogContributionAvailableForSubscriptionUseCaseFactory,
  deps: [CatalogRepository],
};


const getCatalogContributionAvailableForContributionUseCaseFactory = (
  catalogRepository: CatalogRepository
) => new GetCatalogContributionAvailableForContributionUseCase(catalogRepository);

const getCatalogContributionAvailableForContributionUseCaseProvider = {
  provide: GetCatalogContributionAvailableForContributionUseCase,
  useFactory: getCatalogContributionAvailableForContributionUseCaseFactory,
  deps: [CatalogRepository],
};

const catalogRepositoryProvider = {
  provide: CatalogRepository,
  useClass: CatalogImplRepository,
};

@NgModule({
  providers: [
    getCatalogDocumentTypesUseCaseProvider,
    getCatalogContributionPricesUseCaseProvider,
    getCatalogAdvanceAvailableUseCaseProvider,
    getCatalogContributionAvailableForSubscriptionUseCaseProvider,
    getCatalogContributionAvailableForContributionUseCaseProvider,
    catalogRepositoryProvider,
  ],
  imports: [CommonModule],
})
export class CatalogModule {}
