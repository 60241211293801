import { Observable } from 'rxjs';
import { PaginatedData } from 'src/base/wrappers/paginated-data';

import { UseCase } from 'src/domain/base/use-case';
import { AdvancesAvailableModel } from 'src/domain/models/advance-available.model';
import { CatalogModel } from 'src/domain/models/catalog.model';
import { CatalogRepository } from 'src/domain/repositories/catalog.repository';
export class GetCatalogAdvancesAvailableUseCase
  implements UseCase<{pageNumber?: number; pageSize?: number; savingsPlanId :string}, PaginatedData<AdvancesAvailableModel>>
{
  constructor(private catalogRepository: CatalogRepository) {}

  execute(
    params : {pageNumber?: number, pageSize?: number, savingsPlanId :string}
  ): Observable<PaginatedData<AdvancesAvailableModel>> {
    return this.catalogRepository.getCataloAdvancesAvailable(params.pageNumber ?? 1,
      params.pageSize ?? 100,params.savingsPlanId);
  }
}
