import { Observable } from 'rxjs';

import { UseCase } from 'src/domain/base/use-case';
import { TravelPlanModel } from 'src/domain/models/travel-plan.model';
import { TravelPlanRepository } from 'src/domain/repositories/travel-plan.repository';

export class DeletePlanUseCase
  implements UseCase<{ id: string }, {}>
{
  constructor(private travelPlanRepository: TravelPlanRepository) {}

  execute(params: { id: string }): Observable<{}> {
    return this.travelPlanRepository.deletePlan(params.id);
  }
}
