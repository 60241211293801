import { ActionReducerMap } from '@ngrx/store';
import { LoaderState, loaderReducer } from './reducers/loader.reducer';
import { SimulatorState, simulatorReducer } from './reducers/simulator.reducer';

export interface AppState {
  loader: LoaderState;
  simulator: SimulatorState;
}

export const ROOT_REDUCERS: ActionReducerMap<AppState> = {
  loader: loaderReducer,
  simulator: simulatorReducer,
};
