import { Mapper } from 'src/base/utils/mapper';

import { UserModel } from 'src/domain/models/user.model';
import { UserEntity } from '../entities/user-entity';
import { ProfileEntity } from '../entities/profile-entity';
import { ProfileModel } from 'src/domain/models/profile.model';

export class ProfileMapper extends Mapper<
  ProfileEntity,
  ProfileModel
> {
  override mapTo(param: ProfileModel): ProfileEntity {
    return {
      ...param,
    };
  }
  override mapFrom(param: ProfileEntity): ProfileModel {
    return {
      ...param,
    };
  }
}
