import { createReducer, on } from '@ngrx/store';
import * as simulatorActions from '../actions/simulator.actions';
import { CalculateDataModel } from 'src/domain/simulation/models/send.data.model';

export interface SimulatorState {
  infoForSimulation?: CalculateDataModel & { savingsPlanId?: string };
}

export const initialState: SimulatorState = {};

export const simulatorReducer = createReducer(
  initialState,
  on(simulatorActions.setInfoForSimulation, (state, { infoForSimulation }) => ({
    ...state,
    infoForSimulation,
  })),
  on(simulatorActions.clearInfoForSimulation, (state) => ({
    ...state,
    infoForSimulation: undefined,
  }))
);
