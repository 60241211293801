import { Observable } from 'rxjs';
import { PaginatedData } from 'src/base/wrappers/paginated-data';

import { UseCase } from 'src/domain/base/use-case';
import { TransactionModel } from 'src/domain/models/transaction.model';
import { PlanContractRepository } from 'src/domain/repositories/plan-contract.repository';
import { TravelPlanRepository } from 'src/domain/repositories/travel-plan.repository';
export class GetTransationsListUseCase
  implements UseCase<{ planId: string }, PaginatedData<TransactionModel>>
{
  constructor(private planContractRepository: PlanContractRepository) {}

  execute(params: { planId: string }): Observable<PaginatedData<TransactionModel>> {
    return this.planContractRepository.getTransactionsList(params.planId);
  }
}
