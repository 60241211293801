import { Observable } from 'rxjs';
import { UseCase } from 'src/domain/base/use-case';

import { Injectable, inject } from '@angular/core';

import { AuthRepository } from '../repositories/auth.repository';
import { AuthModel } from '../models/auth-model';
import { AuthenticationModel } from 'src/domain/models/auth.model';

@Injectable({
  providedIn: 'root',
})
export class LoginUseCase implements UseCase<AuthModel, AuthenticationModel> {
  authRepository = inject(AuthRepository);
  execute(authModel: AuthModel): Observable<AuthenticationModel> {
    return this.authRepository.login(authModel);
  }
}
