<div class="modal-backdrop" (click)="closeModal()"></div>
<div class="modal-content">
  <h3>Validemos tu identidad</h3>
  <div
    *ngIf="isError && !isLoading"
    class="error-message"
    style="margin-bottom: 20px"
  >
    Código incorrecto, intenta nuevamente.
  </div>
  <div *ngIf="!isError && !isLoading" style="margin-bottom: 20px">
    <div *ngIf="!isReSent">Se envió a tu correo un código de validación</div>
    <div *ngIf="isReSent && !isErrorReSent">Se reenvió a tu correo un código de validación</div>
  </div>
  <div *ngIf="isLoading && !isReSent" style="margin-bottom: 20px">Validando código...</div>
  <div *ngIf="isLoading && isReSent " style="margin-bottom: 20px">Reenviando código...</div>
  <div *ngIf="!isLoading" class="input-container">
    <input
      type="number"
      placeholder="Ingresa el código"
      (input)="changeText($event)"
      class="styled-input"
    />
  </div>
  <div *ngIf="isLoading" class="loading-container">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div *ngIf="!isLoading" class="modal-actions">
    <shared-action-button
      textButton="Validar"
      (click)="validateCode()"
      [disabled]="validationCode.length < 6"
    ></shared-action-button>
    <shared-action-button
      textButton="Cancelar"
      [whiteButton]="true"
      (click)="closeModal()"
    ></shared-action-button>
  </div>
  <div *ngIf="!isLoading" class="resend-container">
    <button
      class="resend-button"
      [disabled]="!canResend"
      (click)="onResendCode()"
    >
      Reenviar código {{ canResend ? "" : "(" + resendTimer + ")" }}
    </button>
  </div>
  <div *ngIf="isErrorReSent" class="resend-container">
    No se pudo reenviar el código, intenta nuevamente.
  </div>
</div>
