import { Observable } from 'rxjs';
import { inject } from '@angular/core';

import { UseCase } from 'src/domain/base/use-case';
import { AuthenticationModel } from 'src/domain/models/auth.model';
import { AuthRepository } from '../repositories/auth.repository';

export class RefreshTokenUseCase
  implements UseCase<{ token: string }, AuthenticationModel>
{
  private authRepository = inject(AuthRepository);

  execute(params: { token: string }): Observable<AuthenticationModel> {
    return this.authRepository.refreshToken(params.token);
  }
}
