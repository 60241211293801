import { Observable } from 'rxjs';
import { PlanContractModel } from '../models/plan-contract/plan-contract.model';
import { PaginatedData } from 'src/base/wrappers/paginated-data';
import { PlanContractShortModel } from '../models/plan-contract/plan-contract-short.model';
import { TravelPlanState, TravelPlanSummaryModel } from '../models/travel-plan.model';
import { PaymentSummaryEntity } from 'src/data/repositories/travel-plan/entities/payment-summary.entity';
import { ContributionModel } from '../models/contribution.model';
import { TransactionModel } from '../models/transaction.model';
import { AvailableContractModel } from '../models/available-contract.model';

export abstract class PlanContractRepository {

  abstract getPlan(id: string): Observable<PlanContractModel>;
  abstract getPlans(
    state: TravelPlanState,
    pageNumber: number,
    pageSize: number
  ): Observable<PaginatedData<PlanContractShortModel>>;

  abstract getPaymentSummary(planId: string): Observable<PaymentSummaryEntity>;

  abstract postTransactionContribution(contribution: ContributionModel,planId:string): Observable<ContributionModel>;

  abstract getPlansSummary(
    state: TravelPlanState,
    pageNumber: number,
    pageSize: number
  ): Observable<PaginatedData<TravelPlanSummaryModel>>;


  abstract getTransactionsList(
    planId: string
  ): Observable<PaginatedData<TransactionModel>>;

  abstract getAvailableContractForSubscription(): Observable<PaginatedData<AvailableContractModel>>;

  abstract getAvailableContractForContribution(idPlan:string): Observable<PaginatedData<AvailableContractModel>>;

}
