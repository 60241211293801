<div class="container-results bg-white" #simulation>
  <shared-header textHeader="Simulador de presupuesto"></shared-header>
  <div class="container">
    <div class="ctn-info">
      <h1 class="title-info-ds">Resultados de la simulación del ahorro requerido para <span style="font-weight: 600;">
          {{results?.destination?.name}}</span>
      </h1>
      <div class="info d-grid d-md-block gap-4 align-items-center">
        <div class="ctn-img">
          <img [src]="results?.destination?.image" class="destintation-img" alt="Destino" *ngIf="!isSharing" />
          <img src="\assets\images\simulation-travel.jpg" alt="Destino" [class.d-none]="!isSharing"
            [class.d-block]="isSharing" />
        </div>
        <div class="subcontainer d-flex align-items-center">
          <div class="container-summary">
            <div class="col-izq">
              <h1 class="title-info" style="font-size: 24px; margin-right: 50px; text-align: left;">
                Planes de ahorro<br>sugeridos
              </h1>
            </div>
            <div class="container-info">
              <img class="icons-info" src="\assets\icons\flight.svg" alt="Destino" />
              <p class="textinfo">{{ results?.destination?.name }}</p>
            </div>
            <div class="container-info">
              <img class="icons-info" src="\assets\icons\calendar_month.svg" alt="Duración" />
              <h1 class="textinfo">{{ results?.numberDays }} Días</h1>
            </div>
            <div class="container-info">
              <img class="icons-info" src="\assets\icons\group.svg" alt="Personas" />
              <h1 class="textinfo">{{ results?.numberPeople }} Viajeros</h1>
            </div>
            <div class="container-info">
              <img class="icons-info" src="\assets\icons\balance_diner_icon.svg" alt="Valor" />
              <h1 class="textinfo">
                {{
                results?.approxPrice | currency : "COP" : "" : "1.0-0" : "es-CO"
                }}
                COP
              </h1>
              <app-tool-tip [tooltipData]="'Valor de referencia'"></app-tool-tip>
            </div>
          </div>
          <div class="d-none share-button d-md-block" [class.d-md-none]="isSharing" *ngIf="canShare">
            <shared-action-button id="Btn-Compartir-Cotizacion" [whiteButton]="true" (click)="shareSimulation()" buttonHeight="initial">
              <div class="d-flex gap-2 py-2 px-1 align-items-center justify-content-center">
                <span class="cvu-icon cvu-icon-share"></span>
                Compartir cotización
              </div>
            </shared-action-button>
          </div>
        </div>
      </div>
      <div class="d-md-none share-button btn-mobile-view mt-4 mb-2" [class.d-none]="isSharing" *ngIf="canShare">
        <shared-action-button id="Btn-Compartir-Cotizacion" [whiteButton]="true" (click)="shareSimulation()" buttonHeight="initial" maxWidth="100%">
          <div class="d-flex gap-2 py-2 px-1 align-items-center justify-content-center" style="min-height: 2.3rem;">
            <span class="cvu-icon cvu-icon-share"></span>
            Compartir cotización
          </div>
        </shared-action-button>
      </div>
    </div>
    <div class="containers-max">
      <div id="containers">
        <div class="ctn-card justify-content-center" [class.flex-wrap]="isSharing">
          <app-result-card *ngFor="let item of results?.products; let i = index" [product]="item" [index]="i"
            (selectedProduct)="initPlan($event)"></app-result-card>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" *ngIf="showNoProductsAlert"></div>
  <div class="no-products position-fixed top-0 end-0 bottom-0 start-0 fade show" tabindex="-1" role="dialog"
    *ngIf="showNoProductsAlert">
    <div class="position-absolute top-50 start-50 translate-middle p-4 text-center mx-auto bg-white rounded">
      <div class="position-absolute end-0 me-4">
        <button type="button" class="btn-close" aria-label="Close"
          (click)="showNoProductsAlert = false; nav();"></button>
      </div>
      <span class="cvu-icon cvu-icon-warning mx-auto mb-4"></span>
      <span class="h1 mb-3 d-block">Lo sentimos</span>
      <p class="mb-0">
        El plan que estás intentando simular supera el valor mensual permitido mediante la plataforma. Por favor
        comunícate con uno de nuestros asesores.
      </p>
    </div>
  </div>
  <div class="footer" [class.d-none]="isSharing">
    <a class="btn-footer" routerLink="/simulator">Si estás opciones no se ajustan a lo que necesitas, te tenemos otras
      alternativas, simula otro viaje</a>
  </div>

  <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#shareModal" id="btn-gen-img">
    Share modal
  </button>

  <div class="modal fade" id="shareModal" tabindex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-center bg-blur">
          <div *ngIf="!isSharing" class="d-flex justify-content-center">
            <shared-action-button [whiteButton]="true" (click)="shareSimulation()">
              <div class="d-flex gap-2 align-items-center justify-content-center">
                <span class="cvu-icon cvu-icon-share"></span>
                Compartir cotización
              </div>
            </shared-action-button>
          </div>
          <div class="modal-footer border-0 py-4">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
