import { Mapper } from 'src/base/utils/mapper';

import { PlanContractShortModel } from 'src/domain/models/plan-contract/plan-contract-short.model';
import { PlanContractShortEntity } from '../entities/plan-contract-short.entity';
import {
  TravelPlanState,
} from 'src/domain/models/travel-plan.model';
export class ShortPlanContractMapper extends Mapper<
PlanContractShortEntity,
  PlanContractShortModel
> {
  override mapFrom(param: PlanContractShortEntity): PlanContractShortModel {
    return {
      ...param,
      state: TravelPlanState[param.state as keyof typeof TravelPlanState],
    };
  }

  override mapTo(param: PlanContractShortModel): PlanContractShortEntity {
    throw new Error('Method not implemented.');
  }
}
