import { Mapper } from 'src/base/utils/mapper';
import { ResponseDataPlaceEntity } from 'src/domain/simulation/entities/entities';
import { ResponseDataPlaceModel } from 'src/domain/simulation/models/place.model';
import { Data } from '../../../domain/simulation/models/place.model';

export class SimulationDataImplementationRepositoryMapper extends Mapper<
ResponseDataPlaceEntity,
ResponseDataPlaceModel
> {
    override mapTo(param: ResponseDataPlaceModel): ResponseDataPlaceEntity {
        throw new Error('Method not implemented.');
    }
    mapFrom(param: ResponseDataPlaceEntity): ResponseDataPlaceModel {
        return {
            code:param.code,
            status:param.status,
            message:param.message,
            data:param.data
         }
    }
}
