export const setData = (key: string, data: any) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getData = (key: string) => {
  if (localStorage.hasOwnProperty(key)) {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } else {
    return null;
  }
};

export const removeData = (key: string) => {
  localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
  localStorage.clear();
};
