import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetPlacesUseCase } from 'src/domain/simulation/usecases/get-places-useacase';
import { SimulationDomainModule } from '../../../../../domain/simulation/simulation-domain.module';
import { Place } from 'src/domain/simulation/models/place.model';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { finalize } from 'rxjs';
import { SendDataSimulationUseCase } from '../../../../../domain/simulation/usecases/post-data-simulation-usecase';
import { CalculateDataModel } from 'src/domain/simulation/models/send.data.model';
import { ResultsService } from '../../service/result.service';
import { SharedModule } from 'src/presentation/app/shared/shared.module';
import { ActionButtonComponent } from 'src/presentation/app/shared/components/action-button/action-button.component';
import { GeolocationService } from 'src/presentation/app/shared/services/gelocation/geolocation.service';
import { Position } from 'src/presentation/models/position';
import { GetAvalaibleDaysUseCase } from 'src/domain/simulation/usecases/get-available-days-useacase';
import { LoadingComponent } from '../loading/loading.component';
import {
  hideLoader,
  showLoader,
} from 'src/presentation/app/store/actions/loader.actions';
import { AuthService } from 'src/presentation/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.css'],
  standalone: true,
  providers: [GetPlacesUseCase, SendDataSimulationUseCase],
  imports: [
    SimulationDomainModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    ActionButtonComponent,
    LoadingComponent,
  ],
})
export class SimulatorComponent implements OnInit {
  loading: boolean = false;
  placeList: Place[] = [];
  public simulationForm: FormGroup;
  public backRoute = 'landing';

  constructor(
    private router: Router,
    private getPlacesUc: GetPlacesUseCase,
    private sendDataSimulation: SendDataSimulationUseCase,
    private fb: FormBuilder,
    private resultsService: ResultsService,
    private geolocationService: GeolocationService,
    private getAvailableDaysUsecase: GetAvalaibleDaysUseCase,
    private store: Store,
    private authService: AuthService
  ) {
    this.simulationForm = this.fb.group({
      destinationId: ['', [Validators.required]],
      numberDays: ['', [Validators.required]],
      numberPeople: ['', [Validators.required]],
    });
  }

  public daysOptions = [{ label: 1, value: 1 }];

  public peopleOptions = [
    { label: '1 Viajero', value: 1 },
    { label: '2 Viajeros', value: 2 },
    { label: '3 Viajeros', value: 3 },
    { label: '4 Viajeros', value: 4 },
    { label: '5 Viajeros', value: 5 },
    { label: '6 Viajeros', value: 6 },
    { label: '7 Viajeros', value: 7 },
    { label: '8 Viajeros', value: 8 },
  ];

  origin: Position = { latitude: 0, longitude: 0 };

  ngOnInit(): void {
    this.getPlaces();
    this.geolocationService.getCurrentPosition().subscribe({
      next: (position) => {
        this.origin = position;
      },
      error: (error) => {
        console.error('Error getting geolocation:', error);
      },
    });

    if (this.authService.isAuthenticated()) {
      this.backRoute = 'account'
    }
  }

  private getPlaces(): void {
    this.store.dispatch(showLoader({}));

    this.getPlacesUc.execute().subscribe((res) =>
      res.data.data.map((place) => {
        this.placeList.push(place);
      }, this.store.dispatch(hideLoader()))
    );
  }

  onDestinationChange() {
    const destinationId = this.simulationForm.get('destinationId')!.value;

    if (destinationId) {
      this.getAvailableDays(destinationId);
    }
  }

  getAvailableDays(destinationId: string) {
    this.store.dispatch(showLoader({}));

    this.getAvailableDaysUsecase
      .execute({ destinationId: destinationId })
      .pipe(finalize(() => this.store.dispatch(hideLoader())))
      .subscribe({
        next: (days) => {
          let listDaysMap: { label: number; value: number }[] = [];

          days.forEach((day, index) => {
            listDaysMap.push({ label: day, value: index });
          });

          this.daysOptions = listDaysMap;
        },
      });
  }

  onSubmit() {
    const simulationData: CalculateDataModel = {
      origin: {
        latitude: this.origin.latitude,
        longitude: this.origin.longitude,
      },
      destinationId: this.simulationForm.get('destinationId')?.value,
      numberDays:
        this.daysOptions[this.simulationForm.get('numberDays')?.value].label,
      numberPeople: this.simulationForm.get('numberPeople')?.value,
    };

    this.sendSimulationData(simulationData);
  }

  sendSimulationData(simulationData: CalculateDataModel) {
    this.loading = true;

    this.sendDataSimulation.execute(simulationData).subscribe({
      next: (plan) => {
        this.resultsService.results = plan;
        setTimeout(() => {
          if (plan.id) {
            this.router.navigate(['/account/plans/draft', plan.id]);
          } else {
            this.router.navigate(['/results']);
          }
        }, 3000);
      },
      error: () => {
        this.router.navigate(['/simulator']);
      },
      complete: () => {
        setTimeout(() => {
          this.loading = false;
        }, 3000);
      },
    });
  }
}
