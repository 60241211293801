import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'shared-action-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.css'],
})
export class ActionButtonComponent {
  @Input() disabled: boolean = false;
  @Input() textButton: string = '';
  @Input() whiteButton: boolean = false;
  @Input() buttonHeight: string = '56px';
  @Input() maxHeight: string = '56px';
  @Input() maxWidth: string = '350px';
  @Input() borderRadius: string = '16px';
  @Input() fitContent: string = 'auto';
  @Output() buttonEvent = new EventEmitter();

  public onClick(): void {
    this.buttonEvent.emit();
  }
}
