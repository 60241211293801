import { Mapper } from 'src/base/utils/mapper';
import { AdvancesAvailableEntity } from '../entities/advance-available.entity';
import { AdvancesAvailableModel } from 'src/domain/models/advance-available.model';



export class AdvanceAvailableMapper extends Mapper<
  AdvancesAvailableEntity,
  AdvancesAvailableModel
> {
  override mapTo(param: AdvancesAvailableModel): AdvancesAvailableEntity {
    return {
      ...param,
    };
  }
  override mapFrom(param: AdvancesAvailableEntity): AdvancesAvailableModel {
    return {
      ...param,
    };
  }
}
