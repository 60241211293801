import { Observable } from 'rxjs';
import { PaginatedData } from 'src/base/wrappers/paginated-data';

import { UseCase } from 'src/domain/base/use-case';
import { PlanContractShortModel } from 'src/domain/models/plan-contract/plan-contract-short.model';
import { TravelPlanState } from 'src/domain/models/travel-plan.model';
import { PlanContractRepository } from 'src/domain/repositories/plan-contract.repository';
export class GetPlansContractUseCase
  implements
    UseCase<
      {
        state: TravelPlanState;
        pageNumber?: number;
        pageSize?: number;
      },
      PaginatedData<PlanContractShortModel>
    >
{
  constructor(private planContractRepository: PlanContractRepository) {}

  execute(params: {
    state: TravelPlanState;
    pageNumber?: number;
    pageSize?: number;
  }): Observable<PaginatedData<PlanContractShortModel>> {
    return this.planContractRepository.getPlans(
      params.state,
      params.pageNumber ?? 1,
      params.pageSize ?? 100
    );
  }
}
