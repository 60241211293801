import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthModel } from 'src/domain/auth/models/auth-model';
import AuthSource from './auth.source';
import { AuthenticationModel } from 'src/domain/models/auth.model';
import { ResponseData } from 'src/base/wrappers/response-data';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends AuthSource {
  private readonly baseAuthUrl: string;
  private readonly baseUserUrl: string;

  constructor(private readonly http: HttpClient) {
    super();
    this.baseAuthUrl = `${environment.uriBase}Auth/`;
    this.baseUserUrl = `${environment.uriBase}User`;
  }

  override register(authModel: AuthModel): Observable<boolean> {
    return this.http.post(`${this.baseUserUrl}`, authModel).pipe(
      map((res: any) => res.status),
      catchError((error: HttpErrorResponse) => {
        console.error(error.error);
        if (error.status === 409) {
          return throwError(() => 'El usuario ya existe');
        }
        return throwError(
          () =>
            'Disculpa, no pudimos crear tu cuenta, por favor intenta de nuevo'
        );
      })
    );
  }

  login(authModel: AuthModel): Observable<AuthenticationModel> {
    return this.http
      .post<ResponseData<AuthenticationModel>>(
        `${this.baseAuthUrl}login`,
        authModel
      )
      .pipe(
        map((res) => {
          if (res.data.user.role !== 'Customer') {
            throw new Error(
              `Acceso Denegado: No tienes los permisos necesarios para acceder a esta función.`
            );
          }

          return res.data;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            return throwError(
              () => 'Correo electrónico o contraseña incorrectos'
            );
          }

          return throwError(() =>
            error.message == ''
              ? `Disculpa, no pudimos iniciar sesion, por favor intenta de nuevo`
              : error.message
          );
        })
      );
  }

  override recovery(
    authModel: AuthModel,
    isUpdate: boolean
  ): Observable<boolean> {
    let params = new HttpParams();

    if (isUpdate) {
      params = params.set('mode', 'update');
    }

    return this.http
      .post(`${this.baseAuthUrl}forgot-password`, authModel, { params })
      .pipe(
        map((res: any) => res.status),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400) {
            return throwError(() => 'El email no ha sido encontrado');
          }

          return throwError(
            () =>
              'Disculpa, no pudimos crear tu cuenta, por favor intenta de nuevo'
          );
        })
      );
  }

  override refreshToken(refreshToken: string): Observable<AuthenticationModel> {
    return this.http
      .post(`${this.baseAuthUrl}refresh-token`, { refreshToken })
      .pipe(map((res: any) => res.data));
  }
}
