import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SimulatorComponent } from './simulation/pages/simulator/simulator.component';
import { ResultsComponent } from './simulation/pages/results/results.component';
import { authGuard } from './shared/guards/auth.guard';
import { LandingPageComponent } from 'src/presentation/app/landing-page/landing-page.component';

const routes: Routes = [
  {
    path: '',
    title: 'CVU',
    component: LandingPageComponent,
  },
  {
    path: 'auth',
    loadChildren: () => import(`./auth/auth.module`).then((m) => m.AuthModule),
  },
  {
    path: 'account',
    title: 'Cuenta | CVU',
    loadChildren: () =>
      import(`./account/account.module`).then((m) => m.AccountModule),
    canMatch: [authGuard],
  },
  {
    path: 'simulator',
    title: 'Simula tu ahorro | CVU',
    component: SimulatorComponent,
  },
  {
    path: 'results',
    title: 'Resultado de simulación | CVU',
    component: ResultsComponent,
  },
  {
    path: 'landing',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
