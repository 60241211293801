import { Observable } from 'rxjs';

import { UseCase } from 'src/domain/base/use-case';
import { ProfileModel } from 'src/domain/models/profile.model';
import { UserModel } from 'src/domain/models/user.model';
import { UserRepository } from 'src/domain/repositories/user.repository';
export class PutUserUseCase
  implements UseCase<{ user: ProfileModel, idUser:string }, ProfileModel>
{
  constructor(private userRepository: UserRepository) {}

  execute(params: { user: ProfileModel , idUser:string}): Observable<ProfileModel> {
    return this.userRepository.putUser(params.user,params.idUser);
  }
}
