export default [
  {
    pregunta: '¿Qué plazos de ahorro ofrece CVU?',
    respuesta:
      'Ofrecemos planes de ahorro para viajes a 36, 24 y 18 aportes, cada uno de estos se realiza mensualmente, de esta manera si lo haces oportunamente, tendrás tu ahorro completo a tiempo para tu viaje. Además, con tu constancia y disciplina, recibes beneficios adicionales que hacen de tu plan de ahorro un buen negocio.',
  },
  {
    pregunta: '¿En qué puedo usar mi plan de ahorro?',
    respuesta:
      'Puedes usar el dinero ahorrado para cualquier gasto de viaje o el de tus acompañantes a cualquier destino. Así que el plan de ahorro no es sólo para ti si no para que viajes con familiares y amigos a cualquier lugar de Colombia y el mundo. Puedes usar el plan de ahorro para adquirir los siguientes servicios turísticos con nuestros aliados estratégicos: Tiquetes, Traslados, Hospedaje, Experiencias en el destino: shows y eventos, parques temáticos, museos, entre otros. Paquetes todo incluido, Cruceros. Tarjetas de Asistencias.',
  },
  {
    pregunta: '¿Mi viaje debe estar limitado a fechas o destinos específicos?',
    respuesta:
      'No. Una vez has completado tu plan de ahorro, puedes hacer tu viaje usando el ahorro disponible en cualquier momento y a cualquier destino. No estás limitado por fechas específicas o por un número reducido de destinos. Puedes viajar en cualquier momento, a cualquier lugar del mundo, vivir las experiencias que siempre has querido en el destino, y con los acompañantes que prefieras.',
  },
  {
    pregunta:
      '¿Debo tener un viaje ya definido para adquirir un plan de ahorro?',
    respuesta:
      'No. Puedes comenzar a ahorrar para uno o más viajes que pienses hacer en el futuro. Mientras ahorras defines todos los detalles: destinos, experiencias que quieres vivir, acompañantes de tu aventura, temporadas. Recuerda que con planeación las cosas salen mejor. También, si lo requieres, con nuestras agencias de viajes aliadas, puedes tener toda la asesoría necesaria para planificar tu viaje y disfrutarlo al máximo.',
  },
  {
    pregunta:
      '¿Qué pasa si en un mes no puedo realizar mi aporte o debo suspender temporalmente la realización de los mismos?',
    respuesta:
      'No te preocupes, tu ahorro sigue en CVU. Puedes retomar la realización de tus aportes en cualquier momento para lo cual tenemos un equipo de apoyo para acompañarte en este proceso.',
  },
  {
    pregunta: '¿Puedo usar el dinero que tengo ahorrado en cualquier momento?',
    respuesta:
      'Cuando completas los aportes del plan de ahorro para viajes seleccionado, tendrás la disponibilidad total del dinero ahorrado, el cual podrás utilizar en la compra de servicios turísticos con nuestros aliados estratégicos. Si quieres viajar antes de culminar el plan de ahorro, puedes disponer de una parte de tu ahorro según lo establecido en la Tabla de Disponibles que te fue explicada cuando adquiriste el contrato de plan de ahorro. Si quieres conocer los detalles o tienes dudas, comunícate con nuestra área de gestión y experiencia de cliente.',
  },
  {
    pregunta: '¿El plan de ahorro tiene algún costo?',
    respuesta:
      'El contar con un acompañamiento permanente en tu proyecto de ahorro para lograr ese viaje anhelado es nuestro compromiso, por ello dispondremos un equipo especializado de recaudo para que realices tus aportes, asesores de servicio para que atiendan tus inquietudes en cualquier momento, beneficios adicionales y una infraestructura adecuada que garantice la custodia y administración segura y eficiente de tus recursos. Así como las entidades financieras cobran una cuota de manejo por sus tarjetas de crédito o por el uso de los servicios, el plan de ahorro tiene una cuota de administración correspondiente al 5% del valor de cada aporte que hagas a tu plan de ahorro, más su correspondiente IVA.',
  },
  {
    pregunta: '¿Cómo puedo realizar los aportes del plan de ahorro?',
    respuesta:
      'En Círculo de Viajes Universal, te ofrecemos diferentes alternativas para la realización de los aportes de tu plan de ahorro para viaje: Recaudo personalizado: Un gestor de servicio te visitará en el lugar que nos indiques para recaudar el dinero del aporte. Aplica sólo en las ciudades disponibles para este tipo de recaudo. Débito automático desde tu cuenta bancaria. Sistema PSE. Cargo recurrente a tarjeta de crédito.',
  },
  {
    pregunta:
      '¿Puedo cambiar el valor del aporte mensual o el número de aportes?',
    respuesta:
      'Tu meta es el viaje que siempre has querido. La forma de lograrlo es siendo disciplinado con el ahorro y provisionando el dinero necesario para el viaje; por lo tanto, una vez iniciado el contrato de plan de ahorro no es posible modificar el número de aportes del mismo. Sin embargo, si quieres aumentar el valor mensual de tu aporte, puedes adquirir otro contrato de plan de ahorro.',
  },
  {
    pregunta: '¿Qué entidades vigilan a Círculo de Viajes Universal?',
    respuesta:
      'Círculo de Viajes Universal S.A.S. es una compañía legalmente constituida en Colombia con más de 50 años continuos en el mercado. Además de esa trayectoria, operamos con altos estándares de ética y responsabilidad, y somos vigilados y controlados por la Superintendencia de Sociedades y la relación con el consumidor está supeditada a las actuaciones de la Superintendencia de Industria y Comercio.',
  },
];


// ANTIGUAS PREGUNTAS
// [
//   {
//     pregunta: '¿Qué plazos de ahorro ofrece CVU?',
//     respuesta:
//       'Ofrecemos planes de ahorro para viajes de 36, 24 y 18 aportes. Cada aporte es mensual, así que si pagas oportunamente, tendrás tu ahorro completo a tiempo para tu viaje. Además, con los aportes a tiempo, recibes muchos beneficios adicionales que hacen de tu plan de ahorro un buen negocio.',
//   },
//   {
//     pregunta: '¿En qué puedo usar mi plan de ahorro?',
//     respuesta:
//       'Puedes usar el dinero ahorrado para cualquier gasto de tu viaje o el de tus acompañantes a cualquier destino. Así que el plan de ahorro no es sólo para que hagas viajes solo si no para que viajes con familiares y amigos a cualquier lugar de Colombia y el mundo.',
//     usos: [
//       'Tiquetes y traslados en cualquier medio: avión, barco, carro, globo, etc.',
//       'Hospedaje en cualquier hotel del mundo.',
//       'Experiencias en el destino: alimentación, shows y eventos, parques temáticos, museos, etc.',
//       'Paquetes turísticos, cruceros.',
//       'Asistencia en viaje',
//     ],
//   },
//   {
//     pregunta: '¿El ahorro es sólo para mi o también para mis acompañantes?',
//     respuesta:
//       'El ahorro es para tu viaje y el de tus acompañantes. Así, puedes viajar con tus padres, tus hijos, tu pareja, los amigos cercanos o cualquier otra persona especial con la que quieras hacer realidad un viaje único.',
//   },
//   {
//     pregunta: '¿Mi viaje debe estar limitado a fechas o destinos específicos?',
//     respuesta:
//       'No. Una vez has completado tu plan de ahorro, puedes hacer tu viaje usando el ahorro disponible en cualquier momento y a cualquier destino. No estás limitado por fechas específicas o por un número reducido de destinos. Puedes viajar en cualquier momento, a cualquier lugar del mundo, vivir las experiencias que siempre has querido en el destino, y con los acompañantes que prefieras.',
//   },
//   {
//     pregunta:
//       '¿Debo tener un viaje ya definido para adquirir un plan de ahorro?',
//     respuesta:
//       'No. Puedes comenzar a ahorrar para uno o más viajes que pienses hacer en el futuro. Mientras ahorras defines todos los detalles: destinos, experiencias que quieres vivir, acompañantes de tu aventura, tiquetes, documentos de viaje, etc. Recuerda que con planeación las cosas salen mejor. También, si lo requieres, con nuestra agencia de viajes aliada, Viajes Circular, puedes tener toda la asesoría necesaria para planificar tu viaje y vivirlo al máximo.',
//   },
//   {
//     pregunta: '¿Qué pasa si un mes no ahorro?',
//     respuesta:
//       'Tu ahorro sigue en CVU. Sin embargo, no participas en los sorteos mensuales y no puedes acceder a los puntos faltantes para comprar en comercios aliados.',
//   },
//   {
//     pregunta: '¿Puedo usar el dinero que tengo ahorrado en cualquier momento?',
//     respuesta:
//       'Cuando completas los aportes del plan de ahorro para viajes, recibes todo el dinero ahorrado y puedes usarlo únicamente para turismo y gastos relacionados con viajes. \n\nSi quieres viajar antes de culminar el plan de ahorro, puedes disponer de una parte de tu ahorro según la Tabla de Disponibles que hace parte integral del contrato del Plan de Ahorro. Sólo podrás usar el disponible a partir del séptimo aporte. Si quieres conocer los detalles, agenda una llamada con nuestros asesores.',
//   },

//   {
//     pregunta: '¿Puedo retirarme de CVU en cualquier momento?',
//     respuesta:
//       'El objetivo principal del plan de ahorro es que cumplas con tu viaje soñado y para ello, lo mejor es que completes los aportes de tu plan. Si por motivos de fuerza mayor debes retirarte del plan de ahorro, puedes hacerlo siguiendo el proceso correspondiente ante CVU. Ten en cuenta que el dinero que recibirás será proporcional a tu aporte, según la Tabla de Disponibles de tu plan. Agenda una llamada con nuestros asesores para conocer todos los detalles.',
//   },
//   {
//     pregunta: '¿El plan de ahorro tiene algún costo?',
//     respuesta:
//       'Sí. Así como las entidades financieras cobran una cuota de manejo por sus tarjetas de crédito o por el uso de los servicios, el plan de ahorro tiene una comisión de administración correspondiente al 5% del valor de cada aporte que hagas a tu plan de ahorro. A esta comisión, debes agregarle el IVA. Así, si tu aporte es de $100.000, la comisión de administración es de sólo $5.000 + IVA. Mucho menor a la cuota de manejo de una tarjeta de crédito (aprox. $25.000 / mes) o a la tasa de interés de un crédito (+20% E.A.) y, además, con tu plan de ahorro para viaje accedes a beneficios adicionales como el sorteo mensual para duplicar tu ahorro, las noches de cortesía en hoteles seleccionados y los puntos para comprar en comercios aliados que lo hacen un buen negocio.',
//   },
//   {
//     pregunta: '¿Cómo puedo pagar los aportes del plan de ahorro?',
//     respuesta:
//       'En Cïrculo de Viajes Universal, te ofrecemos diferentes alternativas para el pago de los aportes de tu plan de ahorro para viaje:',
//     alternativas_pago: [
//       'Recaudo personalizado: Un gestor de Círculo de Viajes Universal te visitará en el lugar que nos indiques para recaudar el dinero del aporte. Aplica sólo en las ciudades disponibles para este tipo de recaudo.',
//       'Débito automático desde tu cuenta bancaria.',
//       'Sistema PSE',
//       'Tarjeta de crédito',
//     ],
//   },
//   {
//     pregunta:
//       '¿Puedo cambiar el valor del aporte mensual o el número de aportes?',
//     respuesta:
//       'Tu meta es el viaje que siempre has querido. La forma de lograrlo es siendo disciplinado con el ahorro y guardando el dinero necesario para el viaje. Así, NO es posible modificar el número de aportes del ahorro. Sin embargo, si quieres aumentar el valor mensual de tu aporte, puedes adquirir otro contrato de plan de ahorro.',
//   },
//   {
//     pregunta: '¿Mi ahorro está seguro?',
//     respuesta:
//       'Sí. Círculo de Viajes Universal S.A.S. es una compañía legalmente constituida en Colombia con más de 50 años en el mercado. Además de esa trayectoria, operamos con altos estándares de ética y responsabilidad, y somos vigilados por las autoridades colombianas:',
//     seguridad: [
//       'Estamos facultados para operar por la Ley 300 de 1996 y demás normas que la adicionen o modifiquen.',
//       'Actuamos bajo la inspección, vigilancia y control del Ministerio de Comercio, Industria y Turismo, la Superintendencia de Sociedades, y la Superintendencia de Industria y Comercio.',
//     ],
//   },
// ];
