import { Observable } from 'rxjs';

import { UseCase } from 'src/domain/base/use-case';
import { TravelPlanModel } from 'src/domain/models/travel-plan.model';
import { TravelPlanRepository } from 'src/domain/repositories/travel-plan.repository';

export class SignContractUseCase
  implements UseCase<{ idPlan: string }, TravelPlanModel>
{
  constructor(private travelPlanRepository: TravelPlanRepository) {}

  execute(params: { idPlan: string }): Observable<TravelPlanModel> {
    return this.travelPlanRepository.signContract(params.idPlan);
  }
}
