import { Mapper } from 'src/base/utils/mapper';

import { TransactionEntity } from '../../plan-contract/entities/transaction.entity';
import { TransactionModel } from 'src/domain/models/transaction.model';
import { TransactionType } from '../../../../domain/models/transaction.model';
import { ContributionEntity } from '../entities/contribution.entity';
import { ContributionModel } from 'src/domain/models/contribution.model';

export class ContributionMapper extends Mapper<
  ContributionEntity,
  ContributionModel
> {
  override mapTo(param: ContributionModel): ContributionEntity {
    return {
      ...param,
    };
  }
  override mapFrom(param: ContributionEntity): ContributionModel {
    return {
      ...param,
    };
  }
}
