import { Observable } from 'rxjs';
import { AuthModel } from '../models/auth-model';
import { AuthenticationModel } from 'src/domain/models/auth.model';

export abstract class AuthRepository {
  abstract login(authModel: AuthModel): Observable<AuthenticationModel>;
  abstract register(authModel: AuthModel): Observable<boolean>;
  abstract recovery(authModel:AuthModel,isUpdate:boolean): Observable<boolean>;
  abstract refreshToken(refreshToken: string): Observable<AuthenticationModel>;
}
