import { Mapper } from 'src/base/utils/mapper';

import { UserFirebaseEntity } from '../entities/user-firebase.entity';
import { UserFirebaseModel } from 'src/domain/models/user-firebase.model';

export class UserFirebaseMapper extends Mapper<
  UserFirebaseEntity,
  UserFirebaseModel
> {
  override mapTo(param: UserFirebaseModel): UserFirebaseEntity {
    return {
      ...param,
    };
  }
  override mapFrom(param: UserFirebaseEntity): UserFirebaseModel {
    return {
      ...param,
    };
  }
}
