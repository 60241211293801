<div class="benefits-section">
  <div class="benefit-container container">
    <div class="row">
      <div class="col-xxl-5 col-lg-6"></div>
      <div class="col-xxl-7 col-lg-6">
        <h2>Con tu plan de ahorro</h2>
        <div #benefitsContent class="benefits-content">
          <ul>
            <li class="benefit mb-0 pb-2 mb-sm-4 pb-sm-3" id="benefit1">
              <div class="img-icon d-flex">
                <img src="\assets\icons\check.svg" alt="">
              </div>
              <div>
                <h4>Recibe noches de alojamiento gratis</h4>
                <p>Con la compra de un plan de ahorro disfruta de este beneficio. <strong>Aplican condiciones y
                    restricciones</strong></p>
              </div>
            </li>
            <li class="benefit mb-0 pb-2 mb-sm-4 pb-sm-3" id="benefit2">
              <div class="img-icon d-flex">
                <img src="\assets\icons\check.svg" alt="">
              </div>
              <div>
                <h4>Puntos para redimir en diferentes comercios</h4>
                <p>Realizar tus aportes oportunamente, te entrega puntos canjeables en una gran diversidad de comercios.
                  <strong>Aplican condiciones y restricciones</strong>
                </p>
              </div>
            </li>
            <li class="benefit mb-0 pb-2 mb-sm-4 pb-sm-3" id="benefit3">
              <div class="img-icon d-flex">
                <img src="\assets\icons\check.svg" alt="">
              </div>
              <div>
                <h4>Duplica tu ahorro en nuestro sorteo</h4>
                <p>Participa en el sorteo promocional mensual y podrás ganar el valor total de tu plan de ahorro.
                  <strong>Aplican condiciones y restricciones</strong>
                </p>
              </div>
            </li>
            <li class="benefit mb-0 pb-2 mb-sm-4 pb-sm-3" id="benefit4">
              <div class="img-icon d-flex">
                <img src="\assets\icons\check.svg" alt="">
              </div>
              <div>
                <h4>Tienes diversos canales de recaudo</h4>
                <p>Variedad de canales para la realización de tus aportes y acompañamiento permanente en tu proyecto de
                  ahorro, con el fin de que logres alcanzar tus sueños de viaje.</p>
              </div>
            </li>
            <li class="benefit mb-0 pb-2 mb-sm-4 pb-sm-3" id="benefit5">
              <div class="img-icon d-flex">
                <img src="\assets\icons\check.svg" alt="">
              </div>
              <div>
                <h4>Recibes asesoría en la organización de tu viaje</h4>
                <p>Te acompañamos en la planeación de tus viajes con nuestros aliados especializados en los servicios
                  turísticos</p>
              </div>
            </li>
            <li class="benefit mb-1 pb-3" id="benefit6">
              <div class="img-icon d-flex">
                <img src="\assets\icons\check.svg" alt="">
              </div>
              <div>
                <h4>No pagas intereses</h4>
                <p>Porque estarás haciendo un ahorro, no adquiriendo un crédito</p>
              </div>
            </li>
          </ul>
        </div>
        <div class="dots-container mt-0" #dotsContainer>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
        <div class="ps-md-5 ms-md-3 button-container d-flex d-md-block justify-content-center">
          <shared-action-button [routerLink]="['/simulator']">Comenzar</shared-action-button>
        </div>
      </div>
    </div>
  </div>
</div>
