import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthRepository } from 'src/domain/auth/repositories/auth.repository';
import AuthSource from '../services/auth.source';
import { AuthModel } from 'src/domain/auth/models/auth-model';
import { AuthenticationModel } from 'src/domain/models/auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthImplementationRepository extends AuthRepository {
  constructor(private authSource: AuthSource) {
    super();
  }
  override register(authModel: AuthModel): Observable<boolean> {
    return this.authSource.register(authModel);
  }
  override recovery(authModel:AuthModel,isUpdate:boolean): Observable<boolean> {
    return this.authSource.recovery(authModel,isUpdate);
  }

  override login(authModel: AuthModel): Observable<AuthenticationModel> {
    return this.authSource.login(authModel);
  }

  override refreshToken(refreshToken: string): Observable<AuthenticationModel> {
    return this.authSource.refreshToken(refreshToken);
  }
}
