<div class="loader-component position-fixed top-0 end-0 bottom-0 start-0 fade show" tabindex="-1" role="dialog" *ngIf="isLoading$ | async">
    <div class="position-absolute top-50 start-50 translate-middle d-flex flex-column align-items-center">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="message-loading text-center p-4" *ngIf="message$ | async">
            {{ message$ | async }}
        </div>
    </div>
</div>
<div class="modal-backdrop fade show" *ngIf="isLoading$ | async"></div>
