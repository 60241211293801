import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoaderState } from '../reducers/loader.reducer';

const selectLoaderState = createFeatureSelector<LoaderState>('loader');

export const isLoaderVisible = createSelector(
  selectLoaderState,
  (state: LoaderState) => state.show
);

export const loaderMessage = createSelector(
  selectLoaderState,
  (state: LoaderState) => state.message
);
