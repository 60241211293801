import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AlertErrorComponent } from './components/alert-error/alert-error.component';
import { CheckboxComponent } from './components/checkbox-icon/checkbox-icon.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { CreditCardFormatDirective } from './directives/credit-card-format/credit-card-format.directive';
import { ExpirationDateFormatDirective } from './directives/expiration-date-format/expiration-date-format.directive';
import { HideEmailPartPipe } from './pipes/hide-email.pipe';
import { ValidateOtpModalComponent } from './components/validate-otp-modal/validate-otp-moda.component';
import { ActionButtonComponent } from './components/action-button/action-button.component';

@NgModule({
  declarations: [
    AlertErrorComponent,
    HeaderComponent,
    CreditCardFormatDirective,
    ExpirationDateFormatDirective,
    CheckboxComponent,
    StarRatingComponent,
    HeaderMenuComponent,
    HideEmailPartPipe,
    ValidateOtpModalComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    ActionButtonComponent,
  ],
  exports: [
    AlertErrorComponent,
    HeaderComponent,
    CreditCardFormatDirective,
    ExpirationDateFormatDirective,
    CheckboxComponent,
    StarRatingComponent,
    HeaderMenuComponent,
    HideEmailPartPipe,
    ValidateOtpModalComponent,
  ],
})
export class SharedModule {}
