import { Observable } from 'rxjs';
import {
  TravelPlanModel,
  TravelPlanShortModel,
  TravelPlanState,
  TravelPlanSummaryModel,
} from '../models/travel-plan.model';
import { PaginatedData } from '../../base/wrappers/paginated-data';
import { PaymentSummaryEntity } from 'src/data/repositories/travel-plan/entities/payment-summary.entity';
import { TransactionModel } from '../models/transaction.model';
import { ContributionModel } from '../models/contribution.model';
import { AvailableContractModel } from '../models/available-contract.model';

export abstract class TravelPlanRepository {
  abstract getPlan(id: string): Observable<TravelPlanModel>;

  abstract getPlans(
    state: TravelPlanState,
    pageNumber: number,
    pageSize: number
  ): Observable<PaginatedData<TravelPlanShortModel>>;

  abstract getPlansSummary(
    state: TravelPlanState,
    pageNumber: number,
    pageSize: number
  ): Observable<PaginatedData<TravelPlanSummaryModel>>;

  abstract getPlanSummary(planId: string): Observable<TravelPlanSummaryModel>;


  abstract deletePlan(id: string): Observable<{}>;

  abstract patchEditPlan(  planId: string,
    numberContributions: number,
    monthlyContributionPrice: number): Observable<TravelPlanModel>;



  abstract signContract(idPlan: string): Observable<TravelPlanModel>;



}
