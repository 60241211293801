import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ResultsEntity } from 'src/domain/simulation/entities/results.entities';
import { ResultsService } from '../../service/result.service';
import { CreateImageService } from 'src/presentation/app/shared/services/create-image/create-image.service';
import {
  hideLoader,
  showLoader,
} from 'src/presentation/app/store/actions/loader.actions';
import { AlertsService } from 'src/presentation/app/shared/services/alerts/alerts.service';
import { setInfoForSimulation } from 'src/presentation/app/store/actions/simulator.actions';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css'],
})
export class ResultsComponent implements OnInit {
  @ViewChild('simulation') simulation: ElementRef<HTMLElement> =
    {} as ElementRef<HTMLElement>;

  showNoProductsAlert: boolean = false;
  results?: ResultsEntity;
  idToken: string = '';
  canShare: boolean = false;
  isSharing: boolean = false;

  private navigator?: Navigator;
  private image: File | null = null;

  constructor(
    private router: Router,
    private resultsService: ResultsService,
    private createImageService: CreateImageService,
    private store: Store,
    private alert: AlertsService
  ) {}

  ngOnInit(): void {
    this.results = this.resultsService.results;
    this.navigator = navigator;

    if (!this.results) {
      this.router.navigate(['/simulator']);
    }

    if (this.results?.products?.length === 0) {
      this.showNoProductsAlert = true;
    }

    this.canShare = !(!this.navigator?.canShare || !this.navigator?.share);
  }

  nav() {
    this.router.navigate(['/simulator']);
  }

  initPlan(productIndex: number) {
    const plan = this.results;

    if (
      !plan?.destination?.id ||
      !plan?.numberDays ||
      !plan?.numberPeople ||
      !plan?.products?.[productIndex]?.savingsPlan?.id ||
      !plan?.products?.[productIndex]?.numberContributions
    ) {
      return;
    }

    const infoForSimulation = {
      origin: plan.origin,
      destinationId: plan.destination.id,
      numberDays: plan.numberDays,
      numberPeople: plan.numberPeople,
      savingsPlanId: plan.products[productIndex].savingsPlan.id,
    };

    this.store.dispatch(setInfoForSimulation({ infoForSimulation }));
    this.router.navigate(['/auth/register']);
  }

  shareSimulation(): void {
    if (this.isSharing) {
      return;
    }

    if (!this.navigator?.canShare || !this.navigator?.share) {
      this.alert.showAlertWarning(
        'Disculpa, tu navegador no soporta la funcionalidad de compartir. Recuerda que aún puedes tomar una captura de pantalla y compartirla manualmente.'
      );
      return;
    }

    this.store.dispatch(showLoader({}));
    this.isSharing = true;

    if (this.image) {
      this.shareImage();
      return;
    }

    setTimeout(async () => {
      this.store.dispatch(
        showLoader({
          message: 'Generando imagen de la simulación para compartir...',
        })
      );

      this.image = await this.createImage();

      if (!this.image) {
        this.handleError(
          'Ocurrió un problema al intentar generar la imagen de tu simulación, por favor intenta nuevamente.'
        );
      } else {
        (document.querySelector('#btn-gen-img') as HTMLButtonElement)?.click();
      }

      this.store.dispatch(hideLoader());
      this.isSharing = false;
    }, 300);
  }

  private async createImage(): Promise<File | null> {
    let name = this.parseImgName(this.results?.destination.name ?? '');
    name = `Simulación_${name}.jpeg`;

    return await this.createImageService.createImageFromHTML(
      this.simulation.nativeElement,
      name
    );
  }

  shareImage(): void {
    const image = this.image!;

    if (!this.navigator?.canShare({ files: [image] })) {
      this.handleError(
        'Tu navegador no soporta la funcionalidad de compartir archivos.'
      );
      return;
    }

    navigator
      .share({
        title: 'Simulación de viaje a ' + this.results?.destination.name,
        url: window.location.origin + '/simulator',
        files: [image],
      })
      .catch((error) => {
        console.error('Error sharing', error);

        this.handleError(
          'Ocurrió un problema al inentar compartir tu simulación, por favor intenta nuevamente.'
        );
      })
      .finally(() => {
        this.store.dispatch(hideLoader());
        this.isSharing = false;

        const closeBtn = document.querySelector(
          '#shareModal .btn-close'
        ) as HTMLButtonElement;

        closeBtn?.click();
      });
  }

  private handleError(message: string): void {
    this.store.dispatch(hideLoader());
    this.isSharing = false;
    this.alert.showAlertWarning(message);
  }

  private parseImgName(text: string): string {
    return text.replace(/ /g, '_');
  }
}
