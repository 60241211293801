import { Injectable } from '@angular/core';
import { BehaviorSubject, take, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  private alertState = new BehaviorSubject<{
    show: boolean;
    text: string;
    backgroundColor: string;
    icon: string;
  }>({
    show: false,
    text: '',
    backgroundColor: '',
    icon: '',
  });

  alertState$ = this.alertState.asObservable();

  showAlertSuccess(text: string, autoHide: boolean = true) {
    this.alertState.next({
      show: true,
      text,
      backgroundColor: '#e1fcde',
      icon: 'cvu-icon-success',
    });
    if (autoHide) {
      this.hideAlertAfter(5000);
    }
  }
  showAlertError(
    text: string,
    backgroundColor: string = '#ffd4d8',
    icon: string = 'cvu-icon-failed'
  ) {
    this.alertState.next({ show: true, text, backgroundColor, icon });
    this.hideAlertAfter(5000);
  }

  showAlertWarning(
    text: string,
    backgroundColor: string = '#FFF2D2',
    icon: string = 'cvu-icon-warning'
  ) {
    this.alertState.next({ show: true, text, backgroundColor, icon });
    this.hideAlertAfter(5000);
  }

  hideAlert() {
    this.alertState.next({
      show: false,
      text: '',
      backgroundColor: '',
      icon: '',
    });
  }

  private hideAlertAfter(duration: number) {
    timer(duration)
      .pipe(take(1))
      .subscribe(() => {
        this.hideAlert();
      });
  }
}
