import { Observable } from 'rxjs';
import { UserModel } from '../models/user.model';
import { CatalogModel } from '../models/catalog.model';
import { PaginatedData } from 'src/base/wrappers/paginated-data';
import { AdvancesAvailableModel } from '../models/advance-available.model';

export abstract class CatalogRepository {
  abstract getCatalogDocumentTypes(
    pageNumber: number,
    pageSize: number
  ): Observable<PaginatedData<CatalogModel>>;

  abstract getCataloAdvancesAvailable(
    pageNumber: number,
    pageSize: number,
    savingsPlanId :string,
  ): Observable<PaginatedData<AdvancesAvailableModel>>;
  abstract getCatalogContributionPrices(): Observable<PaginatedData<number>>;
  abstract getCatalogContributionAvailableForSubscription(): Observable<PaginatedData<number>>;
  abstract getCatalogContributionAvailableForSContribution(): Observable<PaginatedData<number>>;


}
