import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ActionButtonComponent } from 'src/presentation/app/shared/components/action-button/action-button.component';
import { AuthService } from 'src/presentation/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  standalone: true,
  imports: [CommonModule, ActionButtonComponent, RouterModule],
})
export class NavBarComponent implements OnInit {
  public isLogged: boolean = false;

  constructor(private router: Router, private authService: AuthService) {
    this.isLogged = this.authService.isAuthenticated();
  }
  ngOnInit(): void {
    this.changeHeaderImage('banner1.png');
  }

  nav() {
    this.router.navigate(['auth/login']);
  }

  headerImageUrl = 'banner1.png';

  detectDeviceType() {
    if (window.innerWidth <= 530) {
      return 'mobile';
    }
    if (window.innerWidth <= 900) {
      return 'tablet';
    } else {
      return 'web';
    }
  }

  changeHeaderImage(imageName: string) {
    var deviceType = this.detectDeviceType();
    if (deviceType === 'tablet') {
      this.headerImageUrl = `tablet/${imageName}`;
    } else if (deviceType === 'mobile') {
      this.headerImageUrl = `movil/${imageName}`;
    } else {
      this.headerImageUrl = imageName;
    }
  }
}
