import { Component, Input, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-tool-tip',
  standalone: true,
  templateUrl: './tool-tip.component.html',
  styleUrls: ['./tool-tip.component.css'],
})
export class ToolTipComponent implements OnInit {
  @Input() tooltipData: string = '';

  ngOnInit(): void {
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }
}
