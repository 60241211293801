import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.css']
})
export class HeaderMenuComponent {



  @Input()
  public backRouter:string ='';

  @Input()
  isIcon:boolean = true;
}
