import { Observable } from 'rxjs';
import { UseCase } from 'src/domain/base/use-case';

import { Injectable, inject } from '@angular/core';
import { SimulationRepository } from '../repositories/simulation.repositories';
import { ResponseDataPlaceModel } from '../models/place.model';

@Injectable({
  providedIn: 'root',
})
export class GetAvalaibleDaysUseCase implements UseCase<{destinationId:string}, number[]> {
  constructor(private simulationRepository: SimulationRepository) {}
  execute(params: { destinationId: string }): Observable<number[]> {
    return this.simulationRepository.getAvailableDays(params.destinationId);
  }
}
