import { Observable } from 'rxjs';

import { UseCase } from 'src/domain/base/use-case';
import { PaginatedData } from 'src/base/wrappers/paginated-data';
import {
  TravelPlanSummaryModel,
  TravelPlanState,
} from 'src/domain/models/travel-plan.model';
import { PlanContractRepository } from 'src/domain/repositories/plan-contract.repository';

export class GetPlansContractSummaryUseCase
  implements
    UseCase<
      {
        state: TravelPlanState;
        pageNumber?: number;
        pageSize?: number;
      },
      PaginatedData<TravelPlanSummaryModel>
    >
{
  constructor(private planContractRepository: PlanContractRepository) {}

  execute(params: {
    state: TravelPlanState;
    pageNumber?: number;
    pageSize?: number;
  }): Observable<PaginatedData<TravelPlanSummaryModel>> {
    return this.planContractRepository.getPlansSummary(
      params.state,
      params.pageNumber ?? 1,
      params.pageSize ?? 100
    );
  }
}
