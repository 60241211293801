import { Injectable, inject } from '@angular/core';
import { SimulationRepository } from '../repositories/simulation.repositories';
import { Observable } from 'rxjs';
import { CalculateDataModel } from '../models/send.data.model';
import { UseCase } from 'src/domain/base/use-case';
import { ResultsEntity } from '../entities/results.entities';
@Injectable({
    providedIn: 'root',
})
export class SendDataSimulationUseCase implements UseCase<{}, ResultsEntity> {
    simulationRepository = inject(SimulationRepository);
    execute(userSimulationData: CalculateDataModel): Observable<ResultsEntity> {
      return this.simulationRepository.sendDataSimulation(userSimulationData);
    }
}
