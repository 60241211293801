import { Observable } from 'rxjs';
import { ResultsEntity } from 'src/domain/simulation/entities/results.entities';
import { ResponseDataPlaceModel } from 'src/domain/simulation/models/place.model';
import { CalculateDataModel } from 'src/domain/simulation/models/send.data.model';

export abstract class SimulationSource {
    abstract getPlaces(): Observable<ResponseDataPlaceModel>;

    abstract sendDataSimulation(
        userSimulationData: CalculateDataModel
      ): Observable<ResultsEntity>;

      abstract getAvailableDays(destinationId:string): Observable<number[]>;

      abstract checkPayment(id: string): Observable<any>;

      abstract checkPayment2(id: string): Observable<any>;
}
