import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  switchMap,
  take,
} from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authService = inject(AuthService);

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.includes(environment.uriBase)) {
      request = this.addTokenHeaderIfTokenExists(request);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          request.url.includes(environment.uriBase) &&
          !request.url.includes('Auth') &&
          error.status === 401
        ) {
          return this.handle401Error(request, next);
        }

        throw error;
      })
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token = this.authService.getRefreshToken();

      if (token) {
        return this.authService.refreshToken(token).pipe(
          switchMap((authData) => {
            this.isRefreshing = false;

            this.authService.setAuthData({
              ...this.authService.getAuthData()!,
              idToken: authData.idToken,
              refreshToken: authData.refreshToken,
              expiresIn: authData.expiresIn,
            });

            this.refreshTokenSubject.next(authData.idToken);

            return next.handle(this.addTokenHeader(request, authData.idToken));
          }),
          catchError((error) => {
            this.isRefreshing = false;
            this.authService.logout();

            throw error;
          })
        );
      }
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<unknown> {
    const headers = new HttpHeaders({
      // 'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });

    return request.clone({ headers });
  }

  private addTokenHeaderIfTokenExists(
    request: HttpRequest<any>
  ): HttpRequest<unknown> {
    const token = this.authService.getToken();

    if (token) {
      return this.addTokenHeader(request, token);
    }

    return request;
  }
}
